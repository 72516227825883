import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Panel, PanelHeader } from '../../components/panel/panel.jsx';
import CardBasic from '../../components/card/card-basic.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Marker from '../../components/map/marker.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from './../../environment.js';

import Pagination from '../../components/pagination/pagination';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import generalCatchErrorsModal from '../../components/modal/modal-error';

const ParcelaProfile = () => {
  const history = useHistory();
  const { uuid } = useParams();
  const [parcela, setParcela] = useState({});
  const [blocking, setBlocking] = useState(false);

  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const zoom = 17;
  const [center, setCenter] = useState({
    lat: 16,
    lng: -92,
  });

  const [dataVariedades, setDataVariedades] = useState([]);

  const [topHeight] = useState(350);
  const [bottomHeight] = useState(350);

  const [dialogOpen, setDialogOpen] = useState(false);

  const columnsVariedades = React.useMemo(
    () => [
      {
        Header: 'NÚMERO',
        accessor: '_n',
        sortable: false,
        minWidth: 50,
        Cell: cell => {
          return <LabelNumber value={cell.value}></LabelNumber>;
        },
      },
      {
        Header: 'VARIEDAD',
        accessor: 'variedad',
        sortable: true,
      },
      {
        Header: 'NÚMERO DE PLANTAS',
        accessor: 'cantidad_plantas',
        sortable: true,
        Cell: cell => {
          return <LabelNumber value={cell.value}></LabelNumber>;
        },
      },
      {
        Header: '% DE LA PARCELA',
        accessor: 'porcentaje_plantas',
        sortable: true,
        Cell: cell => {
          return <LabelNumber value={cell.value} digits={2} suffix={'%'}></LabelNumber>;
        },
      },
      {
        Header: 'EDAD PROMEDIO',
        accessor: 'edad_plantas',
        sortable: true,
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
      },
    ],
    []
  );

  useEffect(() => {
    if (map != null && maps != null && parcela != null) {
      if (parcela.poligono != null) {
        let poligono = JSON.parse(parcela.poligono);

        let poligono_data = poligono.coordinates[0].map(function(p) {
          return { lat: p[1], lng: p[0] };
        });

        var polygon = new maps.Polygon({
          paths: poligono_data,
          strokeColor: '#ffffff', //parcela.cuenta_categoria_color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#ffffff', //parcela.cuenta_categoria_color,
          fillOpacity: 0.35,
        });
        polygon.setMap(map);

        setCenter({
          lat: parcela.centroide != null ? parcela.centroide_lat : poligono.coordinates[0][0][1],
          lng: parcela.centroide != null ? parcela.centroide_lng : poligono.coordinates[0][0][0],
        });
      }
    }
  }, [map, maps, parcela]);

  const renderMap = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);
      Api.get('parcelas', uuid)
        .then(response => {
          setParcela(response.data[0]);
          var variedades = response.data[0].variedades.map(function(item, i) {
            return {
              ...item,
              _n: i + 1,
              ciclo: response.data[0].ciclo,
            };
          });
          setDataVariedades(variedades);
          setBlocking(false);
        })
        .catch(e => {
          generalCatchErrorsModal(e.response?.status, '/admin/parcelas/list');
          setBlocking(false);
        });
    }
  }, []);

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/admin/parcelas">Parcelas</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/parcelas/list">Listado parcelas</Link>
            </li>
            <li className="breadcrumb-item active">Perfil parcela</li>
          </ol>

          <button
            onClick={() => history.goBack()}
            type="button"
            className="btn btn-outline-secondary"
            style={{ borderRadius: '20px 20px 20px 20px' }}>
            <i className="fa fa-arrow-left"></i> Volver al listado
          </button>
        </Breadcrumb>
        <div className="row justify-content-end ">
          <Link to={{ pathname: `/admin/parcelas/perfil/print/${uuid}` }} className="btn btn-outline-secondary mb-3">
            <i className="fa fa-print" aria-hidden="true"></i> Croquis digitalizado
          </Link>
        </div>

        <Modal isOpen={dialogOpen} toggle={() => setDialogOpen(!dialogOpen)} centered={true} size={'lg'}>
          <ModalHeader toggle={() => setDialogOpen(!dialogOpen)}>Croquis</ModalHeader>
          <ModalBody>
            <img src={`${env.apiURL}/${parcela.croquis}`} style={{ width: '100%' }}></img>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-white" onClick={() => setDialogOpen(!dialogOpen)}>
              Cerrar
            </button>
          </ModalFooter>
        </Modal>

        <div className="row pb-4">
          <div className="col-xl-6">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer height={topHeight}>
                <div className="row">
                  <div className="col-xl-4 col-md-4">
                    <h6 className="font-lato-normal-400">Parcela:</h6>
                    <h3 className="font-lato-normal-500 pb-3">{parcela.nombre || '-'}</h3>

                    <h6 className="font-lato-normal-400">Inspección:</h6>
                    <h3 className="font-lato-normal-500 pb-3">{parcela.inspeccion_fecha || '-'}</h3>

                    <h6 className="font-lato-normal-400">Sombra:</h6>
                    <h3 className="font-lato-normal-500 pb-3">{parcela.inspeccion_sombra || '-'}</h3>

                    {parcela.croquis != null && (
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{ borderRadius: '20px 20px 20px 20px' }}
                        onClick={() => setDialogOpen(!dialogOpen)}>
                        <i className="fa fa-eye"></i> Ver croquis
                      </button>
                    )}
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <div className="map-rounded" style={{ height: '290px', width: '100%' }}>
                      {parcela.poligono != null && (
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: env.googleApiKey }}
                          options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                          center={center}
                          zoom={zoom}
                          onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                          {parcela.centroide != null && (
                            <Marker
                              lat={parcela.centroide_lat}
                              lng={parcela.centroide_lng}
                              color={parcela.cuenta_categoria_color}></Marker>
                          )}
                        </GoogleMapReact>
                      )}
                      {parcela.centroide == null && parcela.poligono == null && (
                        <CardBasic
                          height={topHeight - 60}
                          label="Ubicación no disponible"
                          value={':('}
                          style={{ backgroundColor: env.colors.neutral_1 }}></CardBasic>
                      )}
                    </div>
                  </div>
                </div>
              </CardContainer>
            </BlockUi>
          </div>
          <div className="col-xl-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic
                height={topHeight}
                label="Hectáreas"
                value={(parcela.superficie || 0).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}></CardBasic>
            </BlockUi>
          </div>
          <div className="col-xl-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic
                height={topHeight}
                label="Plantas"
                value={(parcela.plantas || 0).toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}></CardBasic>
            </BlockUi>
          </div>
          <div className="col-xl-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic
                height={topHeight}
                label="Kg estimados de cosecha"
                value={(parcela.estimacion_cosecha || 0).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}></CardBasic>
            </BlockUi>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-xl-12">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer height={bottomHeight}>
                <Panel>
                  <PanelHeader noButton={true} style={{ color: '#FFFFFF', backgroundColor: '#789D16' }}>
                    DATOS DE LA PARCELA
                  </PanelHeader>
                  <Pagination
                    blocking={false}
                    columns={columnsVariedades}
                    data={dataVariedades}
                    pagination={false}></Pagination>
                </Panel>
              </CardContainer>
            </BlockUi>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParcelaProfile;
