import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import { PanelCollapse } from '../../components/panel/panel-collapse.jsx';
import CardBasic from '../../components/card/card-basic.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import env from './../../environment.js';

import Pagination from '../../components/pagination/pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectOption from '../../components/select/select-option';
import './cuentas-comite.css';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import Moment from 'moment';
import Select from 'react-select';
import colourStyles from '../../utils/colourStyles';
import ComiteCondicionesTable from '../../components/tables/comite-condiciones-table';
import getNextCategoria from '../../utils/getNextCategoria';

const CuentaComite = () => {
  const auth = useAuth();
  const { uuid } = useParams();
  const [cuenta, setCuenta] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [blockingSave, setBlockingSave] = useState(false);
  const [superficie, setSuperficie] = useState(0);

  /* Global */
  const [view, setView] = useState(-1);
  const [fechaAut, setFechaAut] = useState(null);

  const [dataCat, setDataCat] = useState([]);

  const [nivelRiesgo, setNivelRiesgo] = useState(null);
  const [nivelesRiesgoData, setNivelesRiesgoData] = useState([]);

  /* 1.- Aprobado Sin Condiciones */
  const [aprobSCObservaciones, setAprobSCObservaciones] = useState('');

  /* 2.- Aprobado Con Condiciones */
  const [isNew, setIsNew] = useState(false);
  const [aprobCCList, setAprobCCList] = useState([]);

  const [conditionCondicion, setConditionCondicion] = useState('');
  const [conditionPlazo, setConditionPlazo] = useState(null);
  const [conditionVerifCumplido, setConditionVerifCumplido] = useState({ value: -1, label: '' });
  const [conditionVerifFecha, setConditionVerifFecha] = useState(null);

  /* 3.- Sancionado */
  const [sancCatNueva, setSancCatNueva] = useState(null);
  const [sancFechaInicio, setSancFechaInicio] = useState(null);
  const [sancFechaTermino, setSancFechaTermino] = useState(null);
  const [sancObservaciones, setSancObservaciones] = useState(null);

  const [errors, setErrors] = useState(null);
  const [errors1, setErrors1] = useState(null);
  const [errors2, setErrors2] = useState(null);
  const [errors3, setErrors3] = useState(null);
  const [errorsCondicion, setErrorsCondicion] = useState(null);

  const hiddenFileInput = React.useRef(null);
  const [firma, setFirma] = useState(null);

  const schemaGlobal = yup.object().shape({
    // cond_total: yup.number().min(0, 'Debe seleccionar una opción'),
    // cond_cumplidas: yup.number().min(0, 'Debe seleccionar una opción'),
    condicion: yup.number().positive('Debe seleccionar una opción'),
    fecha_aut: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    firma_imagen: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe adjuntar su firma'),
  });

  const schema1 = yup.object().shape({
    aprob_sc_observaciones: yup.string().required('Describa las observaciones'),
  });

  const schema2 = yup.object().shape({
    aprob_cc_list: yup.number().min(1),
  });

  const schema3 = yup.object().shape({
    sanc_cat_anterior: yup.number().positive('Debe seleccionar una opción'),
    sanc_cat_nueva: yup.number().positive('Debe seleccionar una opción'),
    sanc_fecha_inicio: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
    sanc_fecha_termino: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
  });

  const schemaCondicion = yup.object().shape({
    condicion: yup.string().required('Describa la condición'),
    plazo: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    verif_cumplido: yup.number().nullable(),
    verif_fecha: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
  });

  const dataS3 = [
    { value: 1, label: 'Aprobado sin condiciones' },
    { value: 2, label: 'Aprobado con condiciones' },
    { value: 3, label: 'Sancionado' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'CONDICIÓN ESTABLECIDA',
        accessor: 'condicion',
        sortable: true,
        minWidth: 100,
      },
      {
        Header: 'PLAZO DE CUMPLIMIENTO',
        accessor: 'plazo_str',
        sortable: true,
        minWidth: 60,
      },
    ],
    []
  );

  useEffect(() => {
    // TODO: fetch from backend
    if (auth.getCertification() === 'certimex') {
      setDataCat([
        { value: 7, label: 'Sin Asignar', color: '#777771' },
        { value: 1, label: 'Transición 0', color: '#D87748' },
        { value: 2, label: 'Transición 1', color: '#FFB94C' },
        { value: 3, label: 'Transición 2', color: '#C7AE34' },
        { value: 4, label: 'Orgánico', color: '#4A6700' },
      ]);
    } else {
      setDataCat([
        { value: 7, label: 'Sin Asignar', color: '#777771' },
        { value: 2, label: 'Transición 1', color: '#FFB94C' },
        { value: 3, label: 'Transición 2', color: '#C7AE34' },
        { value: 5, label: 'Transición 3', color: '#FFDDB0' },
        { value: 6, label: 'Convencional', color: '#8e44ad' },
        { value: 4, label: 'Orgánico', color: '#4A6700' },
      ]);
    }
    setBlocking(true);

    Api.getAll('admin/sic/catalog/niveles_riesgo').then(response => {
      const opts = response.data.map(item => {
        return { value: item['id'], label: item['nombre'], color: item['color'] };
      });
      setNivelesRiesgoData(opts);

      if (uuid != null) {
        Api.get('cuentas', uuid)
          .then(response => {
            response.data = response.data.map(function(item) {
              return {
                ...item,
                _fecha_ingreso: item.fecha_ingreso != null ? item.fecha_ingreso.substr(0, 4) : null,
              };
            });

            setCuenta(response.data[0]);
            let sup = response.data[0].total_superficie;
            if (sup && !isNaN(sup)) {
              sup = parseFloat(sup).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              setSuperficie(sup);
            }

            // if (!response.data[0].id_nivel_riesgo) {
            //   Swal.fire({
            //     icon: 'info',
            //     title: '',
            //     text: `No se puede realizar la evaluación hasta no tener la ficha del productor para el ciclo actual.`,
            //     confirmButtonText: 'Regresar',
            //   }).then(() => {
            //     window.location.href = `/admin/cuentas/perfil/${uuid}`;
            //   });
            // }

            // // eslint-disable-next-line eqeqeq
            const nivelRiesgo = opts.find(it => it.value == response.data[0].id_nivel_riesgo);
            setNivelRiesgo(nivelRiesgo);

            setBlocking(false);
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log('e', e);
            setBlocking(false);
          });
      }
    });
  }, [uuid]);

  const changeView = event => {
    let tempCategory = cuenta?.id_cuenta_categoria;
    if (event == 1) {
      tempCategory = getNextCategoria(tempCategory);
    }
    const cat = dataCat.find(it => it.value == tempCategory);
    setSancCatNueva(cat);
    setView(event);
  };

  const onAddCondition = () => {
    setIsNew(true);

    setConditionCondicion('');
    setConditionPlazo(null);
    setConditionVerifCumplido({ value: -1, label: '' });
    setConditionVerifFecha(null);
  };

  const onSaveCondition = () => {
    var data = {
      condicion: conditionCondicion,
      plazo: conditionPlazo,
      verif_cumplido: conditionVerifCumplido.value,
      verif_fecha: conditionVerifFecha,
    };

    schemaCondicion
      .validate(data, { abortEarly: false })
      .then(function() {
        //Valida
        setErrorsCondicion({
          condicion: null,
          plazo: null,
          verif_cumplido: null,
          verif_fecha: null,
        });

        setIsNew(false);
        setAprobCCList([
          ...aprobCCList,
          {
            condicion: conditionCondicion,
            plazo: conditionPlazo,
            plazo_str: Moment(conditionPlazo).format('DD/MMM/yyyy'),
          },
        ]);
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrorsCondicion(errors);
      });
  };

  const onSubmit = () => {
    let globalData = {
      id_user: auth.user.id,
      id_cuenta: cuenta.id,

      // cond_total: condTotal,
      // cond_cumplidas: condCumplidas,
      condicion: view,
      fecha_aut: fechaAut,
      firma_imagen: firma,
      id_sic: cuenta.id_sic,
      sanc_cat_anterior: cuenta?.id_cuenta_categoria,
      sanc_cat_nueva: sancCatNueva?.value,
    };
    if (nivelRiesgo && nivelRiesgo.value) {
      globalData = {
        ...globalData,
        id_nivel_riesgo: nivelRiesgo.value,
      };
    }

    schemaGlobal
      .validate(globalData, { abortEarly: false })
      .then(function() {
        //Fase 1 Valida
        setErrors({
          // cond_total: null,
          // cond_cumplidas: null,
          condicion: null,
          fecha_aut: null,
          firma_imagen: null,
        });

        //Validar: 1. Aprobada sin condiciones
        if (view === 1) {
          var optionData = {
            /* 1.- Aprobado Sin Condiciones */
            aprob_sc_observaciones: aprobSCObservaciones,
          };
          schema1
            .validate(optionData, { abortEarly: false })
            .then(function() {
              //Valida
              setErrors1({
                aprob_sc_observaciones: null,
              });

              save({
                ...globalData,
                ...optionData,
              });
            })
            .catch(function(err) {
              let errors = err.inner.reduce(function(map, obj) {
                map[obj.path] = obj.message;
                return map;
              }, {});
              setErrors1(errors);
            });
        } else if (view === 2) {
          optionData = {
            /* 2.- Aprobado Con Condiciones */
            aprob_cc_list: aprobCCList,
          };
          schema2
            .validate({ aprob_cc_list: aprobCCList.length }, { abortEarly: false })
            .then(function() {
              //Valida
              setErrors2({
                aprob_cc_list: null,
              });

              save({
                ...globalData,
                ...optionData,
              });
            })
            .catch(function(err) {
              let errors = err.inner.reduce(function(map, obj) {
                map[obj.path] = obj.message;
                return map;
              }, {});
              setErrors2(errors);
            });
        } else {
          optionData = {
            /* 3.- Sancionado */
            sanc_observaciones: sancObservaciones,
          };

          if (sancFechaInicio) optionData = { ...optionData, sanc_fecha_inicio: sancFechaInicio };
          if (sancFechaTermino) optionData = { ...optionData, sanc_fecha_termino: sancFechaTermino };

          schema3
            .validate(optionData, { abortEarly: false })
            .then(function() {
              //Valida
              setErrors3({
                sanc_cat_anterior: null,
                sanc_cat_nueva: null,
                sanc_fecha_inicio: null,
                sanc_fecha_termino: null,
                sanc_observaciones: null,
              });

              save({
                ...globalData,
                ...optionData,
              });
            })
            .catch(function(err) {
              let errors = err.inner.reduce(function(map, obj) {
                map[obj.path] = obj.message;
                return map;
              }, {});
              setErrors3(errors);
            });
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  const save = values => {
    const formData = new FormData();
    for (var key in values) {
      let v = values[key];
      if (v instanceof Date) {
        v = Moment(v).format('yyyy-MM-DD');
      } else if (v instanceof Array) {
        v = JSON.stringify(v);
      }
      formData.append(key, v);
    }

    setBlockingSave(true);
    //CREATE
    Api.createMultiPart('comite_evaluaciones', formData)
      .then(response => {
        let data = response.data[0];
        setBlockingSave(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se registró con éxito la evaluación -> ${data.uuid}`,
        });
        window.location.href = `/admin/cuentas/perfil/${cuenta.uuid}`;
      })
      .catch(e => {
        setBlockingSave(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
  };

  const handleChange = event => {
    setFirma(event.target.files[0]);
  };

  const handleSelectFile = () => {
    hiddenFileInput.current.click();
  };

  const handlerSelectChange = selectedOption => {
    setNivelRiesgo(selectedOption);
  };

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/admin/cuentas">Listado productores</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/cuentas/list">Listado productores</Link>
            </li>
            <li className="breadcrumb-item active">Perfil productor</li>
          </ol>

          <Link to={{ pathname: `/admin/cuentas/perfil/${uuid}` }}>
            <button
              type="button"
              className="btn btn-outline-secondary mt-4"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-arrow-left"></i> Volver al perfil
            </button>
          </Link>
        </Breadcrumb>

        <div className="row pb-4">
          <div className="col-lg-6  mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer>
                <div className="row">
                  <div className="col-lg-5 col-sm-3 text-center">
                    <img src={env.images.profile.productor} alt="imagenProductor" />
                  </div>
                  <div className="col-lg-7 col-sm-9 ">
                    <div className="row" style={{ paddingTop: 16 }}>
                      <div className="col-xl-8 col-md-8">
                        <h6 className="font-lato-normal-400">Productor:</h6>
                        <h3 className="font-lato-normal-500 pb-3">{cuenta.nombre || '-'}</h3>
                        <LabelCategory
                          label={cuenta.cuenta_categoria}
                          color={cuenta.cuenta_categoria_color}></LabelCategory>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <h6 className="font-lato-normal-400">{cuenta.clave_tx || '-'}</h6>
                        <h3 className="font-lato-normal-400 pb-4">{cuenta.codigo_tx || '-'}</h3>
                        {cuenta._fecha_ingreso != null && (
                          <h6 className="font-lato-normal-400">Desde {cuenta._fecha_ingreso}</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CardContainer>
            </BlockUi>
          </div>
          <div className="col-lg col-sm-4 mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic label="Parcelas" value={cuenta.total_parcelas || 0}></CardBasic>
            </BlockUi>
          </div>
          <div className="col-lg col-sm-4 mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic label="Hectáreas" value={superficie}></CardBasic>
            </BlockUi>
          </div>
          <div className="col-lg col-sm-4 mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic label="Plantas" value={cuenta.total_plantas || 0}></CardBasic>
            </BlockUi>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={'100%'}>
              <div className="row">
                <div className="col-xl-12">
                  <PanelCollapse title="COMITÉ DE EVALUACIÓN">
                    <BlockUi tag="div" message="" blocking={blockingSave} renderChildren={true}>
                      <div className="row my-4">
                        <div className="col-12">
                          <span className="label-16 mr-2">
                            Rellena los campos consinderando todas las parcelas del socio:
                          </span>
                        </div>
                      </div>
                      {auth.getCertification() === 'certimex' && (
                        <div className="row my-4">
                          <div className="col-3">
                            <span className="label-16 mr-2">El nivel de riesgo de este productor es:</span>
                          </div>
                          <div className="col-3">
                            <Select
                              loading={blocking}
                              options={nivelesRiesgoData}
                              onChange={handlerSelectChange}
                              value={nivelRiesgo}
                              styles={colourStyles}
                            />
                            {/* //TODO: missing logic for showing errors */}
                          </div>
                        </div>
                      )}
                      <div className="row my-4">
                        <h3>1. Observaciones, Condiciones o sanciones pasadas del productor.</h3>
                      </div>
                      <div className="row my-4">
                        <div className="col-12">
                          <ComiteCondicionesTable id_cuenta={cuenta?.id} blocking={blocking} />
                        </div>
                      </div>
                      <div className="row my-4">
                        <div className="col-12">
                          <h3>2. Basándose en la inspección interna el comité considera al productor:</h3>
                          <SelectOption
                            data={dataS3}
                            setFilter={event => {
                              changeView(event.value);
                            }}
                            style={{ width: 250 }}></SelectOption>
                          {errors?.condicion && <div className="invalid-feedback">{errors?.condicion}</div>}
                        </div>
                      </div>
                      {view === 1 && (
                        <div className="row my-4">
                          <div className="col-12">
                            <h3>Observaciones</h3>
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-control"
                              rows="6"
                              value={aprobSCObservaciones}
                              onChange={event => {
                                setAprobSCObservaciones(event.target.value);
                              }}
                              placeholder="Describa las observaciones"></textarea>
                            {errors1?.aprob_sc_observaciones && (
                              <div className="invalid-feedback">{errors1?.aprob_sc_observaciones}</div>
                            )}
                          </div>
                          <div className="row my-4">
                            <div className="col-4">
                              <h3>Categoría anterior</h3>
                            </div>
                            <div className="col-8">
                              <h3>Nueva categoría</h3>
                            </div>
                            <div className="col-4">
                              <LabelCategory label={cuenta.cuenta_categoria} color={cuenta.cuenta_categoria_color} />
                            </div>
                            <div className="col-8">
                              <Select
                                loading={blocking}
                                options={dataCat}
                                onChange={option => setSancCatNueva(option)}
                                value={sancCatNueva}
                                styles={colourStyles}
                              />
                              {errors3?.sanc_cat_nueva && (
                                <div className="invalid-feedback">{errors3?.sanc_cat_nueva}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {view === 2 && (
                        <div className="row my-4">
                          <div className="col-10">
                            <Pagination
                              blocking={false}
                              columns={columns}
                              data={aprobCCList}
                              pagination={false}></Pagination>
                            {errors2?.aprob_cc_list && (
                              <div className="invalid-feedback">Agregue al menos una condición</div>
                            )}
                          </div>
                          {!isNew && (
                            <div className="col-12">
                              <button
                                onClick={() => {
                                  onAddCondition();
                                }}
                                type="button"
                                className="btn btn-outline-secondary"
                                style={{ borderRadius: '20px 20px 20px 20px' }}>
                                <i className="fa fa-plus"></i> Agregar condición
                              </button>
                            </div>
                          )}
                          {isNew && (
                            <>
                              <div className="col-12">
                                <div className="row my-4">
                                  <div className="col-6">
                                    <h3>Condición establecida</h3>
                                  </div>
                                  <div className="col-6">
                                    <h3>Plazo de cumplimiento</h3>
                                  </div>
                                </div>
                                <div className="row my-4">
                                  <div className="col-6">
                                    <input
                                      className="form-control"
                                      value={conditionCondicion}
                                      onChange={event => {
                                        setConditionCondicion(event.target.value);
                                      }}
                                      placeholder="Escribe la condición"></input>
                                    {errorsCondicion?.condicion && (
                                      <div className="invalid-feedback">{errorsCondicion?.condicion}</div>
                                    )}
                                  </div>
                                  <div className="col-6">
                                    <DatePicker
                                      dateFormat={'dd/MMM/yyyy'}
                                      selected={conditionPlazo}
                                      onChange={date => {
                                        setConditionPlazo(date);
                                      }}
                                      className="form-control"
                                      placeholderText="Seleccione una fecha"
                                    />
                                    {errorsCondicion?.plazo && (
                                      <div className="invalid-feedback">{errorsCondicion?.plazo}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 text-right">
                                <button
                                  onClick={() => {
                                    setIsNew(false);
                                  }}
                                  type="button"
                                  className="btn btn-outline-secondary mr-2"
                                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                                  <i className="fa fa-times"></i> Cancelar
                                </button>
                                <button
                                  onClick={() => {
                                    onSaveCondition();
                                  }}
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                                  <i className="fa fa-plus"></i> Agregar
                                </button>
                              </div>
                            </>
                          )}
                          <div className="row my-4">
                            <div className="col-4">
                              <h3>Categoría anterior</h3>
                            </div>
                            <div className="col-8">
                              <h3>Nueva categoría</h3>
                            </div>
                            <div className="col-4">
                              <LabelCategory label={cuenta.cuenta_categoria} color={cuenta.cuenta_categoria_color} />
                            </div>
                            <div className="col-8">
                              <Select
                                loading={blocking}
                                options={dataCat}
                                onChange={option => setSancCatNueva(option)}
                                value={sancCatNueva}
                                styles={colourStyles}
                              />
                              {errors3?.sanc_cat_nueva && (
                                <div className="invalid-feedback">{errors3?.sanc_cat_nueva}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {view === 3 && (
                        <div>
                          <div className="row my-4">
                            <div className="col-3">
                              <h3>Categoría anterior</h3>
                            </div>
                            <div className="col-3">
                              <h3>Nueva categoría por la sanción</h3>
                            </div>
                            <div className="col-3">
                              <h3>Fecha de inicio de sanción</h3>
                            </div>
                            <div className="col-3">
                              <h3>Fecha de término de sanción</h3>
                            </div>
                            <div className="col-3">
                              <LabelCategory label={cuenta.cuenta_categoria} color={cuenta.cuenta_categoria_color} />
                            </div>
                            <div className="col-3">
                              <Select
                                loading={blocking}
                                options={dataCat}
                                onChange={option => setSancCatNueva(option)}
                                value={sancCatNueva}
                                styles={colourStyles}
                              />
                              {errors3?.sanc_cat_nueva && (
                                <div className="invalid-feedback">{errors3?.sanc_cat_nueva}</div>
                              )}
                            </div>
                            <div className="col-3">
                              <DatePicker
                                selected={sancFechaInicio}
                                dateFormat={'dd/MMM/yyyy'}
                                onChange={date => {
                                  setSancFechaInicio(date);
                                }}
                                className="form-control"
                                placeholderText="Seleccione una fecha"
                              />
                              {errors3?.sanc_fecha_inicio && (
                                <div className="invalid-feedback">{errors3?.sanc_fecha_inicio}</div>
                              )}
                            </div>
                            <div className="col-3">
                              <DatePicker
                                selected={sancFechaTermino}
                                dateFormat={'dd/MMM/yyyy'}
                                onChange={date => {
                                  setSancFechaTermino(date);
                                }}
                                className="form-control"
                                minDate={new Date()}
                                placeholderText="Seleccione una fecha"
                              />
                              {errors3?.sanc_fecha_termino && (
                                <div className="invalid-feedback">{errors3?.sanc_fecha_termino}</div>
                              )}
                            </div>
                          </div>
                          <div className="row my-4">
                            <div className="col-12">
                              <textarea
                                className="form-control"
                                rows="6"
                                value={sancObservaciones}
                                onChange={event => {
                                  setSancObservaciones(event.target.value);
                                }}
                                placeholder="Describa la causa de la sanción"
                              />
                              {errors1?.sanc_observaciones && (
                                <div className="invalid-feedback">{errors1?.sanc_observaciones}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <hr></hr>

                      <div className="row my-4">
                        <div className="col-12">
                          <span className="label-16">Autorización</span>
                        </div>
                      </div>

                      <div className="row my-4">
                        <div className="col-3">
                          <DatePicker
                            selected={fechaAut}
                            dateFormat={'dd/MMM/yyyy'}
                            onChange={date => {
                              setFechaAut(date);
                            }}
                            className="form-control"
                            placeholderText="Seleccione una fecha"
                          />
                          {errors?.fecha_aut && <div className="invalid-feedback">{errors?.fecha_aut}</div>}
                        </div>
                        <div className="col-9">
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            className="btn btn-outline-secondary"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                          <button
                            onClick={handleSelectFile}
                            type="button"
                            className="btn btn-outline-secondary mr-2"
                            style={{ borderRadius: '20px 20px 20px 20px' }}>
                            <i className="fa fa-upload"></i> Añadir firma
                          </button>
                          {firma && (
                            <span className="label-16">
                              <i className="fa fa-paperclip"></i> {firma?.name}
                            </span>
                          )}
                          {!firma && <span className="label-16">Seleccione su firma</span>}
                          {errors?.firma_imagen && <div className="invalid-feedback">{errors?.firma_imagen}</div>}
                        </div>
                      </div>

                      <button
                        onClick={() => onSubmit()}
                        type="button"
                        className="btn btn-sirio mt-4"
                        style={{ borderRadius: '20px 20px 20px 20px' }}>
                        <i className="fa fa-save"></i> Guardar evaluación
                      </button>
                    </BlockUi>
                  </PanelCollapse>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default CuentaComite;
