import * as React from "react"
import env from './../../environment';


class IconMarker extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		
			
    var color = this.props.color || env.colors.secondary_200;
    var width = this.props.width || 31;
    var height = this.props.height || 43;
		
		return (
      <>
        <svg width={width} height={height} style={{marginLeft: -(width / 2), marginTop: -height}} viewBox="0 0 31 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_42239_777)">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.31342 1.83361C10.4748 0.631861 12.907 0.00117493 15.38 0.00117493C17.853 0.00117493 20.2851 0.631861 22.4465 1.83361C24.4178 2.93122 26.1376 4.42911 27.4955 6.23102C28.8533 8.03293 29.8192 10.099 30.3309 12.2964C30.8427 14.4939 30.889 16.7741 30.467 18.9905C30.045 21.2069 29.1639 23.3106 27.8804 25.1662L16.3604 41.8253C16.2501 41.9851 16.1027 42.1158 15.9307 42.206C15.7588 42.2963 15.5675 42.3435 15.3733 42.3435C15.1791 42.3435 14.9877 42.2963 14.8158 42.206C14.6438 42.1158 14.4964 41.9851 14.3861 41.8253L2.86611 25.1662C1.5839 23.3099 0.704227 21.2058 0.2836 18.9893C-0.137027 16.7728 -0.0892977 14.4928 0.423717 12.2958C0.936731 10.0988 1.90368 8.03346 3.26244 6.23244C4.6212 4.43142 6.34171 2.93457 8.31342 1.83808V1.83361ZM15.38 6.00788C17.2794 6.00788 19.1362 6.57113 20.7155 7.62639C22.2948 8.68166 23.5258 10.1816 24.2526 11.9364C24.9795 13.6913 25.1697 15.6222 24.7991 17.4852C24.4286 19.3481 23.5139 21.0593 22.1708 22.4024C20.8277 23.7455 19.1165 24.6602 17.2536 25.0308C15.3906 25.4013 13.4596 25.2111 11.7048 24.4843C9.94993 23.7574 8.45004 22.5264 7.39477 20.9471C6.3395 19.3678 5.77625 17.511 5.77625 15.6116C5.77625 14.3502 6.02473 13.1012 6.50751 11.9359C6.99028 10.7706 7.69788 9.71176 8.5899 8.81995C9.48193 7.92813 10.5409 7.22077 11.7063 6.73827C12.8718 6.25577 14.1208 6.00758 15.3822 6.00788H15.38Z" fill="#F0EFE6"/>
            <path d="M15.3471 25.0566C20.7095 25.0566 25.0566 20.7095 25.0566 15.3471C25.0566 9.98477 20.7095 5.63771 15.3471 5.63771C9.98476 5.63771 5.6377 9.98477 5.6377 15.3471C5.6377 20.7095 9.98476 25.0566 15.3471 25.0566Z" fill={color}/>
          </g>
          <defs>
            <clipPath id="clip0_42239_777">
              <rect width="30.7564" height="42.3457" fill="white" transform="translate(0.191406 -1.52588e-05)"/>
            </clipPath>
          </defs>
        </svg>
        {this.props?.children}
      </>			
		);
	}
}

export default IconMarker;