import React from 'react';

class Card extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row card-dashboard" style={{ height: this.props.height }}>
        <div className="col-xl-3 col-md-3">
          <img src={this.props.image} className="pt-2" />
        </div>
        <div className="col-xl-9 col-md-9">
          <h1 className="font-lato-normal-600">{this.props.value}</h1>
          <h6 className="font-lato-normal-400">{this.props.label}</h6>
        </div>
      </div>
    );
  }
}

export default Card;
