import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const SelectEfectivoMotivo = ({ value, setFilter, placeholder }) => {
  const fontSize = 18;

  const [options] = useState([
    { value: 0, label: placeholder, isDisabled: true },
    { value: 1, label: 'Crédito pre-cosecha' },
    { value: 2, label: 'Pagos de café' },
  ]);

  const [selected, setSelected] = useState(options[0]);

  const handleChange = option => {
    setSelected(option);
    setFilter(option.value);
  };

  useEffect(() => {
    if (value != 0) {
      let sel = options.filter(o => o.value == value);
      setSelected(sel);
    }
  }, [value]);

  return (
    <Select
      options={options}
      value={selected}
      onChange={handleChange}
      styles={{
        valueContainer: base => ({
          ...base,
          paddingLeft: 38,
        }),
        singleValue: base => ({
          ...base,
          fontSize: fontSize,
        }),
        placeholder: base => ({
          ...base,
          fontSize: fontSize,
        }),
        fontSize: fontSize,
      }}
    />
  );
};

export default SelectEfectivoMotivo;
