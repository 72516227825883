import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
// import FormMinSelectApi from '../../nuup/componentes/Forms/FormMinSelectApi.jsx';
import SelectEfectivoMotivo from '../../components/select/select-efectivo-motivo.jsx';
import SelectFromApi from '../../components/select/select-from-api.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ExampleCustomInput from './input';
import * as yup from 'yup';
import { publish } from '../events';
import Moment from 'moment';
import ModalGeneral from './modal-general';

const ModalEfectivo = ({ title }, ref) => {
  const auth = useAuth();

  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [fecha, setFecha] = useState(null);
  const [id, setId] = useState(0);
  const [idCentroAcopio, setIdCentroAcopio] = useState(0);
  const [cantidad, setCantidad] = useState('');
  const [tipoMotivo, setTipoMotivo] = useState(0);
  const [idAcopiador, setIdAcopiador] = useState(0);

  const [filterCA] = useState([{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }]);
  const [filterAco] = useState([
    { column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa },
    { column: 'id_rol', operator: '=', value: 6 },
  ]);

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    fecha: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    id_centro_acopio: yup.string().required('Debe seleccionar un centro de acopio'),
    cantidad: yup
      .number()
      .required('Debe escribir una cantidad')
      .positive(),
    tipo_motivo: yup.string().required('Debe seleccionar un motivo'),
    id_acopiador: yup.string().required('Debe seleccionar un acopiador'),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    add: addHandler,
    edit: editHandler,
  }));

  const addHandler = () => {
    setShow(true);
    setIsNew(true);

    //Resteamos Valores
    setFecha(null);
    setIdCentroAcopio(0);
    setCantidad('');
    setTipoMotivo(0);
    setIdAcopiador(0);
    setErrors(null);
  };

  const editHandler = data => {
    setShow(true);
    setIsNew(false);

    //Asignamos valores 2023-01-03
    setId(data.id);
    setFecha(Date.parse(data.fecha_envio + 'T12:00:00'));
    setIdCentroAcopio(data.id_centros_acopios);
    setCantidad(data.monto);
    setTipoMotivo(data.tipo_motivo);
    setIdAcopiador(data.acopiador);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    var data = {
      fecha: fecha,
      id_centro_acopio: idCentroAcopio,
      cantidad: cantidad,
      tipo_motivo: tipoMotivo,
      id_acopiador: idAcopiador,
    };
    schema
      .validate(data, { abortEarly: false })
      .then(function() {
        //Datos validos
        const data = {
          fecha_envio: Moment(fecha).format('yyyy-MM-DD'),
          id_centros_acopios: idCentroAcopio,
          cantidad: parseFloat(cantidad * 100),
          tipo_motivo: tipoMotivo,
          acopiador: idAcopiador,
        };

        setBlocking(true);

        if (isNew) {
          // REGISTRAR
          Api.Acopio.create('registro_efectivo', data)
            .then(response => {
              if (response.data.success) {
                setBlocking(false);
                setShow(false);
                publish('Efectivo::onSave', response.data.data);
              } else {
                //Ocurrio un error
              }
            })
            .catch(e => {
              setBlocking(false);
            });
        } else {
          // EDITAR
          Api.Acopio.update('registro_efectivo', id, data)
            .then(response => {
              if (response.data.success) {
                setBlocking(false);
                setShow(false);
                publish('Efectivo::onSave', response.data.data);
              } else {
                //Ocurrio un error
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('error: ', e);
              setBlocking(false);
            });
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  return (
    <ModalGeneral
      show={show}
      title={isNew ? `Registrar ${title}` : `Editar ${title}`}
      onSave={validateHandler}
      onClose={() => closeHandler()}
      loading={blocking}>
      <div className="modal-body">
        <div className="row my-4">
          <div className="col-12">
            <DatePicker
              customInput={<ExampleCustomInput />}
              dateFormat={'dd/MMM/yyyy'}
              selected={fecha}
              onChange={date => {
                setFecha(date);
              }}
              wrapperClassName="w-100"
            />
            {errors?.fecha && <div className="invalid-feedback">{errors?.fecha}</div>}
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <SelectFromApi
              id="id_centro_acopio"
              name="id_centro_acopio"
              value={idCentroAcopio}
              placeholder="Seleccione un centro de acopio"
              url="centros_acopios"
              keyValue="id"
              keyLabel="nombre"
              /*showLoading={false}*/ setFilter={e => {
                setIdCentroAcopio(e);
              }}
              filter={filterCA}
              sort={[{ column: 'nombre', sort: 'asc' }]}
            />
            {errors?.id_centro_acopio && <div className="invalid-feedback">{errors?.id_centro_acopio}</div>}
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <i className="fa fa-search" style={{ padding: 14, color: '#ffffff' }}></i>
              </div>
              <input
                type="number"
                name="cantidad"
                className="form-control"
                placeholder="Escriba un monto"
                value={cantidad}
                onChange={event => {
                  setCantidad(event.target.value);
                }}
              />
              <div className="input-group-append">
                <i className="fa fa-chevron-down" style={{ padding: 14, color: '#ffffff' }}></i>
              </div>
            </div>
            {errors?.cantidad && <div className="invalid-feedback">{errors?.cantidad}</div>}
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <SelectEfectivoMotivo
              id="tipo_motivo"
              name="tipo_motivo"
              value={tipoMotivo}
              placeholder="Seleccione un motivo de desembolso"
              setFilter={e => {
                setTipoMotivo(e);
              }}
            />
            {errors?.tipo_motivo && <div className="invalid-feedback">{errors?.tipo_motivo}</div>}
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <SelectFromApi
              id="id_acopiador"
              name="id_acopiador"
              value={idAcopiador}
              placeholder="Seleccione un acopiador"
              url="users"
              keyValue="id"
              keyLabel="nombre"
              /*showLoading={false}*/ setFilter={e => {
                setIdAcopiador(e);
              }}
              filter={filterAco}
              sort={[{ column: 'nombre', sort: 'asc' }]}
            />
            {errors?.id_acopiador && <div className="invalid-feedback">{errors?.id_acopiador}</div>}
          </div>
        </div>
      </div>
    </ModalGeneral>
  );
};

export default forwardRef(ModalEfectivo);
