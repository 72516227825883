import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import ModalAnticipo from '../../components/modal/modal-anticipo.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import Pagination from '../../components/pagination/pagination';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

import { subscribe, unsubscribe } from '../../components/events';

const AnticiposXProductorList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const dialog = React.createRef();

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'ID YA',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {cell.value}
                </p>
              );
            },
          },
          {
            Header: 'CLAVE TX',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              if (cell.row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${cell.row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{cell.value}</span>
                  </Link>
                );
              } else {
                return cell.value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: cell => {
              if (cell.value != null) {
                return (
                  <LabelCategory label={cell.value} color={cell.row.original.cuenta_categoria_color}></LabelCategory>
                );
              } else {
                return cell.value;
              }
            },
          },
        ],
      },
      {
        Header: 'ANTICIPOS',
        columns: [
          {
            Header: 'FECHA DESEMBOLSO',
            accessor: 'fecha_ejecucion',
            sortable: true,
            minWidth: 80,
            Cell: cell => {
              return <LabelDate value={cell.value}></LabelDate>;
            },
          },
          {
            Header: 'IMPORTE TOTAL',
            accessor: 'total',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'CAPITAL DESEMBOLSADO',
            accessor: 'total_anticipos',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'INTERÉS ACUMULADO',
            accessor: 'total_interes',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'TASA INTERÉS',
            accessor: 'tasa_interes',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} digits={1} suffix={'%'}></LabelNumber>;
            },
          },
          {
            Header: 'ANTICIPO RECUPERADO',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO',
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelColor label={cell.value} color={cell.row.original.estatus_color}></LabelColor>;
            },
          },
          {
            Header: 'REGISTRO',
            accessor: 'registro',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return (
                <p className="text-left" style={{ margin: 0 }}>
                  Registrado por {cell.value} el {cell.row.original.fecha_ejecucion}
                </p>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },

      { value: 1, target: 'productor_clave_tx', label: 'ID YA' },
      { value: 2, target: 'productor_codigo_tx', label: 'CLAVE TX' },
      { value: 3, target: 'folio_recibido', label: 'FOLIO RECIBIDO' },
      { value: 4, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 5, target: 'localidad', label: 'LOCALIDAD' },
      { value: 6, target: 'region', label: 'REGIÓN' },
      { value: 7, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 8, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      { value: 9, target: 'fecha_ejecucion', label: 'FECHA DESEMBOLSO' },
      { value: 10, target: 'total', label: 'IMPORTE TOTAL' },
      { value: 11, target: 'total_anticipos', label: 'CAPITAL DESEMBOLSADO' },
      { value: 12, target: 'total_interes', label: 'INTERÉS ACUMULADO' },
      { value: 13, target: 'tasa_interes', label: 'TASA INTERÉS' },
      { value: 14, target: 'total_anticipos_recuperados', label: 'ANTICIPO RECUPERADO' },
      { value: 15, target: 'total_saldo', label: 'SALDO' },
      { value: 16, target: 'estatus', label: 'ESTATUS' },
      { value: 17, target: 'registro', label: 'REGISTRO' },
    ],
    []
  );

  useEffect(() => {
    if (ciclo.id) {
      dialog.current.setIdCiclo(ciclo.id);
    }
  }, [ciclo]);

  useEffect(() => {
    subscribe('Anticipo::onSave', e => handleSave(e));

    return () => {
      unsubscribe('Anticipo::onSave');
    };
  }, []);

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const updateData = id_ciclo => {
    setBlocking(true);
    Api.Acopio.search('anticipos_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgba(240, 224, 207, 0.5)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el anticipo ${event.detail.folio}.`,
    });

    updateData(event.detail.id_ciclo);
  };

  return (
    <div>
      <ModalAnticipo ref={dialog} title="Registrar anticipo"></ModalAnticipo>

      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Anticipos</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('ACOPIOS_ANTICIPOS_X_PRODUCTOR_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/anticipos_acopios/${ciclo?.uuid}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <Pagination
          blocking={blocking}
          selected="id"
          columns={columns}
          data={data}
          pagination={true}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></Pagination>
      </CardContainer>
    </div>
  );
};

export default AnticiposXProductorList;
