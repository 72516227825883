import React from 'react';
import { Redirect } from 'react-router';

import InicioResume from '../pages/admin/inicio-resume.js';

import LoginV3 from '../pages/login-v3.js';

import CooperativasList from '../pages/admin/cooperativas-list.js';
import CooperativasCRUD from '../pages/admin/cooperativas-crud.js';

import UsuariosList from '../pages/admin/usuarios-list.js';
import UsuariosCRUD from '../pages/admin/usuarios-crud.js';

import CuentasResume from '../pages/admin/cuentas-resume.js';
import CuentasList from '../pages/admin/cuentas-list.js';
import CuentasProfile from '../pages/admin/cuentas-profile.js';
import CuentasComite from '../pages/admin/cuentas-comite.js';

import ComitesDetalle from '../pages/admin/comites-detalle.js';

import ParcelasResume from '../pages/admin/parcelas-resume.js';
import ParcelasList from '../pages/admin/parcelas-list.js';
import ParcelasProfile from '../pages/admin/parcelas-profile.js';
import ParcelasProfilePrint from '../pages/admin/parcelas-profile-print.js';

import PadronConcentradoList from '../pages/admin/padron-concentrado-list';
import PadronCompletoList from '../pages/admin/padron-completo-list';
import PadronAltasList from '../pages/admin/padron-altas-list';
import PadronBajasList from '../pages/admin/padron-bajas-list';
import PadronMapasList from '../pages/admin/padron-mapas-list';

import CsvList from '../pages/admin/csv-list';

import LocalidadesList from '../pages/admin/localidades-list.js';

import VersionesList from '../pages/admin/versiones-list.js';

import AcopioResume from '../pages/acopios/acopio-resume';
import AcopioXProductorList from '../pages/acopios/acopio-x-productor-list';
import EntregasXProductorList from '../pages/acopios/entregas-x-productor-list';
import AnticiposXProductorList from '../pages/acopios/anticipos-x-productor-list';
import EntregasEfectivoList from '../pages/acopios/entregas-efectivo-list';
import PrecioXCooperativaList from '../pages/acopios/precio-x-cooperativa-list';
import VincularSicList from '../pages/admin/vincular-sic-list-p1.js';
import VincularSicListP2 from '../pages/admin/vincular-sic-list-p2.js';
import VincularSicListP3 from '../pages/admin/vincular-sic-list-p3.js';
import VincularSicParcelas from '../pages/admin/vincular-sic-parcelas-p1.js';
import VincularSicParcelasP2 from '../pages/admin/vincular-sic-parcelas-p2.js';
import VincularSicParcelasP3 from '../pages/admin/vincular-sic-parcelas-p3.js';
import VincularSicParcelasP4 from '../pages/admin/vincular-sic-parcelas-p4.js';
import Construction from '../components/construction/construction.js';
import VincularSicParcelasNoParcela from '../pages/admin/vincular-sic-parcelas-no-parcela.js';
import VincularSicParcelasNoParcelaConfirm from '../pages/admin/vincular-sic-no-parcela-confirm.js';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/user/login-v3" />,
  },
  {
    exact: true,
    path: '/user/login-v3',
    title: 'Login v3',
    component: () => <LoginV3 />,
  },
  {
    exact: true,
    path: '/dashboard',
    title: 'Dashboard',
    component: () => <InicioResume />,
    //component: () => <Construction />,
  },

  //COOPERATIVAS
  {
    exact: true,
    path: '/admin/cooperativas/list',
    title: 'Cooperativas',
    component: () => <CooperativasList />,
  },
  {
    exact: true,
    path: '/admin/cooperativas/create',
    title: 'Cooperativas',
    component: () => <CooperativasCRUD />,
  },
  {
    exact: true,
    path: '/admin/cooperativas/edit/:uuid',
    title: 'Cooperativas',
    component: () => <CooperativasCRUD />,
  },

  //USUARIOS
  {
    exact: true,
    path: '/admin/usuarios/list',
    title: 'Usuarios',
    component: () => <UsuariosList />,
  },
  {
    exact: true,
    path: '/admin/usuarios/create',
    title: 'Permisos',
    component: () => <UsuariosCRUD />,
  },
  {
    exact: true,
    path: '/admin/usuarios/edit/:uuid',
    title: 'Permisos',
    component: () => <UsuariosCRUD />,
  },

  //CUENTAS
  {
    exact: true,
    path: '/admin/cuentas',
    title: 'Cuentas',
    // component: () => <CuentasResume />,
    component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/cuentas/list',
    title: 'Cuentas',
    component: () => <CuentasList />,
  },
  {
    exact: true,
    path: '/admin/cuentas/perfil/:uuid',
    title: 'Cuentas',
    component: () => <CuentasProfile />,
  },
  {
    exact: true,
    path: '/admin/cuentas/comite/:uuid',
    title: 'Cuentas',
    component: () => <CuentasComite />,
  },

  //COMITE DE APROBACION
  {
    exact: true,
    path: '/admin/comite/:uuid',
    title: 'Comité de aprobación',
    component: () => <ComitesDetalle />,
  },

  //PARCELAS
  {
    exact: true,
    path: '/admin/parcelas',
    title: 'Parcelas',
    // component: () => <ParcelasResume />,
    component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/parcelas/list',
    title: 'Parcelas',
    // component: () => <ParcelasList />,
    component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/parcelas/perfil/:uuid',
    title: 'Parcelas',
    component: () => <ParcelasProfile />,
  },
  {
    exact: true,
    path: '/admin/parcelas/perfil/print/:uuid',
    title: 'Parcelas',
    component: () => <ParcelasProfilePrint />,
  },

  //PADRON
  {
    exact: true,
    path: '/admin/padron/completo/list',
    title: 'Padrón Completo',
    component: () => <PadronCompletoList />,
  },
  {
    exact: true,
    path: '/admin/padron/concentrado/list',
    title: 'Padrón Concentrado',
    component: () => <PadronConcentradoList />,
  },
  {
    exact: true,
    path: '/admin/padron/altas/list',
    title: 'Padrón Altas',
    component: () => <PadronAltasList />,
    //component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/padron/bajas/list',
    title: 'Padrón Bajas',
    component: () => <PadronBajasList />,
    //component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/padron/mapas/list',
    title: 'Padrón Mapas',
    component: () => <PadronMapasList />,
    //component: () => <Construction />,
  },

  //CSV
  {
    exact: true,
    path: '/admin/csv/list',
    title: 'CSV',
    component: () => <CsvList />,
  },

  //LOCALIDADES
  {
    exact: true,
    path: '/admin/localidades/list',
    title: 'Localidades',
    component: () => <LocalidadesList />,
  },

  //VERSIONES
  {
    exact: true,
    path: '/admin/versiones/list',
    title: 'Versiones',
    component: () => <VersionesList />,
  },

  //VINCULAR SIC
  {
    exact: true,
    path: '/admin/vincular-sic/list',
    title: 'Vincular Sic Paso 1',
    component: () => <VincularSicList />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic/list-p2',
    title: 'Vincular Sic Paso 2',
    component: () => <VincularSicListP2 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic/list-p3',
    title: 'Vincular Sic Paso 3',
    component: () => <VincularSicListP3 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list',
    title: 'Vincular Parcelas Paso 1',
    component: () => <VincularSicParcelas />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-p2',
    title: 'Vincular Parcelas Paso 2',
    component: () => <VincularSicParcelasP2 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-p3',
    title: 'Vincular Parcelas Paso 3',
    component: () => <VincularSicParcelasP3 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-p4',
    title: 'Vincular Parcelas Paso 4',
    component: () => <VincularSicParcelasP4 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-No-parcela',
    title: 'Vincular Sic Paso 3(no-parcela)',
    component: () => <VincularSicParcelasNoParcela />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-No-parcela-confirm',
    title: 'Vincular Sic Paso 4(no-parcela)',
    component: () => <VincularSicParcelasNoParcelaConfirm />,
  },

  //ACOPIOS
  {
    exact: true,
    path: '/admin/acopio',
    title: 'Acopio',
    component: () => <AcopioResume />,
  },
  {
    exact: true,
    path: '/admin/acopio/acopio_por_productor/list',
    title: 'Por productor',
    component: () => <AcopioXProductorList />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_por_productor/list',
    title: 'Entregas',
    component: () => <EntregasXProductorList />,
  },
  {
    exact: true,
    path: '/admin/acopio/anticipos_por_productor/list',
    title: 'Anticipos',
    component: () => <AnticiposXProductorList />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_efectivo/list',
    title: 'Efectivo',
    component: () => <EntregasEfectivoList />,
  },
  {
    exact: true,
    path: '/admin/acopio/precio_por_cooperativa/list',
    title: 'Precio',
    component: () => <PrecioXCooperativaList />,
  },
];

export default routes;
