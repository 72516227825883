import React, { useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import CardContainer from '../../components/card/card-container.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';

import SelectColumnFilter from '../../components/selectColumnFilter/SelectColumnFilter.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

const CuentasList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);

  const columns = React.useMemo(
    () => [
      {
        Header: 'CLAVE',
        accessor: 'clave_tx',
        sortable: true,
        minWidth: 60,
      },
      {
        Header: 'CÓDIGO',
        accessor: 'codigo_tx',
        sortable: true,
        minWidth: 80,
      },
      {
        Header: 'NOMBRE PRODUCTOR',
        accessor: 'nombre',
        sortable: true,
        Cell: ({ value, row }) => (
          <Link
            to={{ pathname: `/admin/cuentas/perfil/${row.original.uuid}` }}
            className="m-r-2"
            style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'REGIÓN',
        accessor: 'cuenta_region',
        sortable: true,
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
      },
      {
        Header: 'COMUNIDAD',
        accessor: 'localidad',
        sortable: true,
      },
      {
        Header: 'CATEGORÍA',
        accessor: 'cuenta_categoria',
        sortable: true,
        Cell: ({ value, row }) => (
          <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>
        ),
        minWidth: 90,
      },
      {
        Header: 'N° F. SIC',
        accessor: 'total_tx_sic',
        sortable: true,
        minWidth: 30,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'N° F. PO',
        accessor: 'total_tx_po',
        sortable: true,
        minWidth: 30,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'N° PARCELAS',
        accessor: 'total_parcelas',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return (
            <p className="text-right" style={{ margin: 0 }}>
              {value}
            </p>
          );
        },
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'clave_tx', label: 'CLAVE' },
      { value: 2, target: 'codigo_tx', label: 'CÓDIGO' },
      { value: 3, target: 'nombre', label: 'PRODUCTOR' },
      { value: 4, target: 'cuenta_region', label: 'REGIÓN' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'localidad', label: 'COMUNIDAD' },
      { value: 7, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 8, target: 'total_tx_sic', label: 'N° F. SIC' },
      { value: 9, target: 'total_tx_po', label: 'N° F. PO' },
      { value: 10, target: 'total_parcelas', label: 'N° PARCELAS' },
      { value: 11, target: 'ciclo', label: 'CICLO' },
    ],
    []
  );

  const setFilter = value => {
    setCiclo(value.target);

    setBlocking(true);
    Api.search('cuentas', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: value.target.id },
        { column: 'confirmado', operator: '=', value: 1 },
      ],
      sort: [{ column: 'clave_tx', sort: 'asc' }],
    })
      .then(response => {
        response.data = response.data.map(function(item, i) {
          return {
            ...item,
            _no: i + 1,
          };
        });
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0 } }, useFilters, useSortBy, usePagination);

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/cuentas">Productores</Link>
          </li>
          <li className="breadcrumb-item active">Listado productores</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('CUENTAS_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/cuentas/${ciclo?.uuid}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <div
            className="table-responsive"
            style={{
              marginBottom: 20,
            }}>
            <table className="table" {...getTableProps()} style={{ borderBottom: '1px solid #e4e7ea' }}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, j) => (
                      <th
                        key={j}
                        className=""
                        style={{
                          color: '#5F5F59',
                          backgroundColor: 'rgba(240, 224, 207, 0.5)',
                          border: '0px solid red',
                        }}>
                        <div>{column.filter ? column.render('Filter') : null}</div>
                        <div
                          className="d-flex"
                          style={{ minWidth: column.minWidth ? column.minWidth : '150px' }}
                          {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <span>{column.render('Header')}</span>
                          <span className="ml-auto">
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down fa-fw f-s-14 text-green"></i>
                                ) : (
                                  <i className="fa fa-sort-up fa-fw f-s-14 text-green"></i>
                                )
                              ) : (
                                <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()} style={{ color: '#171C26' }}>
                      {row.cells.map((cell, i) => {
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {pageOptions.length === 0 && !blocking && (
              <div className="w-100 text-center">Sin registros encontrados</div>
            )}
          </div>
          {pageOptions.length > 0 && (
            <div>
              <div className="d-flex align-items-center justify-content-center">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <button type="button" className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      <i className="fa fa-angle-double-left"></i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}>
                      <i className="fa fa-angle-left"></i>
                    </button>
                  </li>
                  <li className="page-item d-flex align-items-center px-2">
                    <div>
                      Página{' '}
                      <strong>
                        {pageIndex + 1} de {pageOptions.length}
                      </strong>
                    </div>
                  </li>
                  <li className="page-item">
                    <button type="button" className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                      <i className="fa fa-angle-right"></i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}>
                      <i className="fa fa-angle-double-right"></i>
                    </button>
                  </li>
                </ul>
                <div className="ml-3 mr-1">Ir a la página:</div>
                <div className="mx-2" style={{ width: 75 }}>
                  <input
                    className="form-control"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      gotoPage(page);
                    }}
                    style={{ height: 42 }}
                  />
                </div>
                <div>
                  <select
                    className="form-control"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}
                    style={{ height: 42 }}>
                    {env.pagination.pageSizes.map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </BlockUi>
      </CardContainer>
    </div>
  );
};

export default CuentasList;
