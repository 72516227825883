import http from "./http-common";
import httpMulti from "./http-common-multi";

class ApiAcopio {

  search(path, data) {
    return http.post(`/admin/acopios/${path}`, data);
  }

  get(path) {
    return http.get(`/admin/acopios/${path}`);
  }

  create(path, data) {
    return http.post(`/admin/acopios/${path}`, data);
  }

  update(path, id, data) {
    return http.put(`/admin/acopios/${path}/${id}`, data);
  }

  delete(path, id) {
    return http.delete(`/admin/acopios/${path}/${id}`);
  }

}

class Api {

  Acopio = new ApiAcopio()

  getAll(path, params = {}) {
    return http.get(`/${path}`, {params : params});
  }

  search(path, data) {
    return http.post(`/${path}_search`, data);
  }

  searchBy(path, data) {
    return http.post(`/${path}_search_by`, data);
  }

  get(path, uuid) {
    return http.get(`/${path}/${uuid}`);
  }

  create(path, data,) {
    return http.post(`/${path}`, data);
  }

  createMultiPart(path, data) {
    return httpMulti.post(`/${path}`, data);
  }

  update(path, uuid, data) {
    return http.put(`/${path}/${uuid}`, data);
  }

  delete(path, uuid) {
    return http.delete(`/${path}/${uuid}`);
  } 

  download(path) {
    return http.get(`/${path}`, { responseType: 'blob' })
  }

}

export default new Api();