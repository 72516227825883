import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';
import Api from '../../api/global.service';
import { useParams, useHistory } from 'react-router-dom';
import CardBasic from '../../components/card/card-basic.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from './../../environment.js';
import CardColindante from '../../components/card/card-colindante';
import MapText from '../../components/card/map-text';
import TerrainDirection from '../../components/map/terrain-direction';
import { useAuth } from '../../components/content/auth';
import parsePoint from '../../utils/parsePoint';

// Estilos adicionales para mejorar impresión
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media print {
    .pagebreak {
      clear: both;
      page-break-after: always !important;
    }

    tr  {
      border: 2px solid #000 !important;
      padding: 5px 10px !important;
    }

    td, th  {
      font-size: 10px !important;
      padding: 5px 10px !important;
    }

  }
`;

const calculateAngle = (cx, cy, ex, ey) => {
  const dy = ey - cy;
  const dx = ex - cx;
  let theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
};

const ParcelaProfilePrint = () => {
  const zoom = 19;
  const componentRef = useRef(null);
  const { uuid } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const [parcela, setParcela] = useState({});
  const [parcelaPolygon, setParcelaPolygon] = useState({});
  const [colindancias, setColindancias] = useState({ norte: null, sur: null, este: null, oeste: null });
  const [blocking, setBlocking] = useState(false);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [lengths, setLengths] = useState([]);
  const [areaGMaps, setAreaGMaps] = useState(null);
  const [pointsOnMap, setPointsOnMap] = useState([]);
  const [nameToPrint, setNameToPrint] = useState('CroquisParcela');
  const [center, setCenter] = useState({ lat: 16, lng: -92 });
  const [angle, setAngle] = useState(0);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameToPrint,
    onAfterPrint: useCallback(() => history.goBack(), [history]),
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
  });

  // Listar A, B, C o listar 0A, 0B en caso de ser número más grande
  const getAlphaOrder = (n, total) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    if (total >= alphabet.length) {
      return Math.floor(0 + n / alphabet.length).toString() + alphabet[n % alphabet.length];
    }
    return alphabet[n];
  };

  const eliminarDuplicados = arr => {
    // eslint-disable-next-line eqeqeq
    return arr.filter((point, index, array) => array.findIndex(t => t.lat == point.lat && t.lng == point.lng) == index);
  };

  useEffect(() => {
    if (map != null && maps != null && parcela != null) {
      if (parcela.poligono != null) {
        const poligono = JSON.parse(parcela.poligono);
        const poligono_data = poligono.coordinates[0].map(function(p) {
          return { lat: p[1], lng: p[0] };
        });

        const polygon = new maps.Polygon({
          paths: poligono_data,
          strokeColor: '#91B833',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: '#91B833',
          fillOpacity: 0.2,
        });
        polygon.setMap(map);

        const latCenter = parseFloat(parcela.centroide != null ? parcela.centroide_lat : poligono.coordinates[0][0][1]);
        const lngCenter = parseFloat(parcela.centroide != null ? parcela.centroide_lng : poligono.coordinates[0][0][0]);
        setCenter({ lat: latCenter, lng: lngCenter });

        // Google api area y convertir a Hectáreas
        const area = maps.geometry.spherical.computeArea(poligono_data) / 10000;
        setAreaGMaps(area?.toFixed(2));

        // Distancia entre cada punto del polígono
        // ubicado entre los dos puntos
        const lengthsTemp = [];
        for (let i = 1; i < poligono_data.length; i++) {
          const curr = poligono_data[i];
          const prev = poligono_data[i - 1];
          const dist = maps.geometry.spherical.computeDistanceBetween(curr, prev);
          // No mostrar distancias muy pequeñas porque se enciman en el mapa
          if (dist > 4) {
            var midPoint = new maps.Point((curr.lat + prev.lat) / 2, (curr.lng + prev.lng) / 2);
            lengthsTemp.push({
              lat: midPoint.x,
              lng: midPoint.y,
              length: dist.toFixed(),
            });
          }
        }
        setLengths(lengthsTemp);

        const poligono_data_filtrado = eliminarDuplicados(poligono_data);

        // Listado de puntos del polígono con su altitud
        const elevator = new maps.ElevationService();
        elevator
          .getElevationForLocations({ locations: poligono_data_filtrado })
          .then(response => {
            const points = [];
            poligono_data_filtrado.forEach((pnt, i) => {
              points.push({
                lat: pnt.lat,
                lng: pnt.lng,
                elevation: response.results[i].elevation.toFixed(),
                title: getAlphaOrder(i, poligono_data_filtrado.length),
              });
            });
            // Find Min and Max points for elevation direction
            const min = points.reduce((prev, curr) => (prev.elevation < curr.elevation ? prev : curr));
            const max = points.reduce((prev, curr) => (prev.elevation < curr.elevation ? curr : prev));
            setAngle(calculateAngle(max.lat, max.lng, min.lat, min.lng));
            setPointsOnMap(points);
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log('error', error);
          });
      }
    }
  }, [map, maps, parcelaPolygon, parcela]);

  const renderMap = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);

      Api.get('parcelas_croquis', uuid)
        .then(response => {
          setParcela(response.data[0]);
          setParcelaPolygon(response.data.find(p => typeof p.poligono === 'string'));
          setNameToPrint(`CroquisParcela_${response.data[0].nombre}_${response.data[0].cuenta}`);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error:', e);
          setBlocking(false);
        });

      Api.get('parcelas_colindancias', uuid)
        .then(response => {
          if (response.data.length > 0) {
            // TODO: Falta extraer info Fertilizantes, pesticidadas, etc.
            const norte = {
              colindante: response.data[0].colindante_norte,
              herbicidas_nombre: response.data[0].cultivosnorte_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivosnorte_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivosnorte_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_norte,
            };
            const sur = {
              colindante: response.data[0].colindante_sur,
              herbicidas_nombre: response.data[0].cultivossur_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivossur_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivossur_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_sur,
            };
            const este = {
              colindante: response.data[0].colindante_este,
              herbicidas_nombre: response.data[0].cultivoseste_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivoseste_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivoseste_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_este,
            };
            const oeste = {
              colindante: response.data[0].colindante_oeste,
              herbicidas_nombre: response.data[0].cultivosoeste_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivosoeste_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivosoeste_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_oeste,
            };

            setColindancias({
              norte: norte,
              sur: sur,
              este: este,
              oeste: oeste,
            });
          }
          setBlocking(false);
        })
        .catch(e => {
          setBlocking(false);
          // eslint-disable-next-line no-console
          console.log('error: ', e);
        });
    }
  }, [uuid]);

  return (
    <div>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        <button
          onClick={() => history.goBack()}
          type="button"
          className="btn btn-outline-secondary mr-2"
          style={{ borderRadius: '20px 20px 20px 20px' }}>
          <i className="fa fa-arrow-left"></i> Volver
        </button>
        <button
          onClick={handlePrint}
          className="btn btn-outline-secondary"
          style={{ borderRadius: '20px 20px 20px 20px' }}
          type="button">
          <i className="fa fa-print" aria-hidden="true"></i> Imprimir
        </button>
        <div className="row pt-4 pl-4">
          <h5>
            <span className="font-weight-bold">NOTA:</span> Para mejores resultados al imprimir te sugerimos ajustar el
            zoom y la posición del mapa.
          </h5>
        </div>
        <div ref={componentRef}>
          <style type="text/css" media="print">
            {'\
						@page { size: landscape; }\
						'}
          </style>
          <div className="row justify-content-end mr-3">
            <img
              style={{
                width: 'auto',
                height: '80px',
                marginRight: '10px',
              }}
              src={`${env.assetURL}${parcela.logo}`}
              alt="logo_cooperativa"
            />
            <img
              style={{
                width: 'auto',
                height: '80px',
              }}
              src={`${env.assetURL}${parcela.certificacion_logo}`}
              alt="logo_ceritifcacion"
            />
          </div>
          <h2 className="page-header center" style={{ textAlign: 'center' }}>
            CROQUIS POR PARCELA
          </h2>
          <table className="table table-striped" type="text/css">
            <tbody>
              <tr>
                <td className="font-weight-bold">Nombre del Productor:</td>
                <td>{parcela.cuenta}</td>
                <td className="font-weight-bold">Nombre de la parcela:</td>
                <td>{parcela.nombre}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Municipio:</td>
                <td>{parcela.municipio}</td>
                <td className="font-weight-bold">Localidad:</td>
                <td>{parcela.localidad}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Coordenadas Geográficas:</td>
                <td colSpan="3">
                  {' '}
                  Lat: {parsePoint(parcela?.centroide_lat)} / Lon: {parsePoint(parcela?.centroide_lng)}
                </td>
              </tr>
              <tr>
                {// eslint-disable-next-line eqeqeq
                //TODO: sustituir por lógica coop config cuando se implemente:
                !(user.id_cooperativa == 17 || user.id_cooperativa == 18) && (
                  <>
                    <td className="font-weight-bold">Area (hectáreas calculadas):</td>
                    <td>{areaGMaps}</td>
                  </>
                )}
                <td className="font-weight-bold">Area (hectáreas reportadas por el productor):</td>
                <td>{parcela.superficie}</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <CardColindante title="Norte" data={colindancias.norte} />
            </div>
            <div className="col-4"></div>
          </div>
          <div className="row">
            <div className="col-3 align-self-sm-center">
              <CardColindante title="Oeste" data={colindancias.oeste} vertical />
            </div>
            <div className="col-6">
              <div className="map-rounded" style={{ height: '300px', width: '100%' }}>
                <img
                  src={env.images.icon.north}
                  style={{
                    height: '30px',
                    position: 'absolute',
                    top: '10px',
                    left: '18px',
                    zIndex: 1000,
                  }}
                  alt="North"
                />
                <TerrainDirection angle={angle} />
                {parcela.poligono != null && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: env.googleApiKey,
                      libraries: ['geometry', 'elevation'],
                    }}
                    options={map => ({ mapTypeId: map.MapTypeId.TERRAIN, rotateControl: true })}
                    center={center}
                    zoom={zoom}
                    onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                    {lengths.map((it, i) => {
                      return <MapText lat={it?.lat} lng={it?.lng} key={i} text={it?.length + 'm'} />;
                    })}
                    {pointsOnMap.map((it, i) => (
                      <MapText lat={it?.lat} lng={it?.lng} key={i} text={it?.title} />
                    ))}
                  </GoogleMapReact>
                )}
                {parcela.centroide === null && parcela.poligono === null && (
                  <CardBasic
                    height={290}
                    label="Polígono no disponible"
                    value={''}
                    style={{ backgroundColor: env.colors.neutral_1 }}></CardBasic>
                )}
              </div>
            </div>
            <div className="col-3 align-self-sm-center">
              <CardColindante title="Este" data={colindancias.este} vertical />
            </div>
          </div>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 mt-3">
              <CardColindante title="Sur" data={colindancias.sur} />
            </div>
            <div className="col-4"></div>
          </div>

          <div className="pagebreak"></div>

          <div className="row">
            <div className="col-4">
              <table className="table table-striped" type="text/css">
                <tbody>
                  <tr>
                    <td className="text-center font-weight-bold" colSpan="4">
                      Coordenadas del croquis
                    </td>
                  </tr>
                  <tr>
                    <td>Punto</td>
                    <td>Latitud</td>
                    <td>Longitud</td>
                    <td>Altitud</td>
                  </tr>
                  {pointsOnMap.map((p, i) => {
                    return (
                      <tr key={i}>
                        <td>{p.title}</td>
                        <td>{parsePoint(p.lat)}</td>
                        <td>{parsePoint(p.lng)}</td>
                        <td>{p.elevation}m</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default ParcelaProfilePrint;
