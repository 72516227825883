import React from 'react';
import env from '../../environment';

export default function Construction() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignIitems: 'center',
        justifyContent: 'center',
        borderWidth: '1px',
        borderColor: 'red',
      }}>
      <h1 className="login-title-text font-forum-normal-400 mt-5">En construcción</h1>
      <img
        src={env.images.spinners.building}
        style={{
          height: '75px',
          position: 'absolute',
          bottom: '25px',
          left: '250px',
        }}
      />
      <img
        src={env.images.login.bg_inicio_back}
        style={{
          position: 'absolute',
          width: '300px',
          height: 'auto',
          bottom: 0,
          right: 0,
        }}
      />
    </div>
  );
}
