import React, { useState, useEffect } from 'react';
import Api from '../../../api/global.service';
import './FormMinSelectApi.css';

const FormMinSelectApi = ({ filter, sort, url, id, name, onChange, keyArray, labelArray, placeholder }) => {
  const [state, setState] = useState({
    items: [],
  });

  useEffect(() => {
    let options = {
      filter: filter ? filter : [],
      sort: sort ? sort : [],
    };

    Api.search(url, options).then(response => {
      let _items = response.data;
      setState({ items: _items });
    });
    // .catch(e => {});
  }, []);

  return (
    <select
      id={id}
      name={name}
      className={`form-control modal-select`}
      defaultValue={0}
      onChange={onChange}
      style={{ padding: '8px 32px 8px 32px', marginRight: '10px' }}>
      <option value={0} disabled={true}>
        {placeholder}
      </option>
      {state.items.map((item, index) => (
        <option key={index} value={item[keyArray]}>
          {item[labelArray]}
        </option>
      ))}
    </select>
  );
};
export default FormMinSelectApi;
