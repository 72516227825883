import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import LinkDownload from '../../components/link/link-download';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';

import Pagination from '../../components/pagination/pagination';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

const PadronConcentradoList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);

  const report = auth.getCertification() === 'mayacert' ? 'may_padron_concentrado' : 'padron_concentrado';

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN GENERAL',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'localidad_clave',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CÓDIGO COMUNIDAD',
            accessor: 'id_localidad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'COMUNIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'MUNICIPIO',
            accessor: 'municipio',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
      {
        Header: 'ORGÁNICOS',
        columns: [
          {
            Header: 'SOCIOS',
            accessor: 'org_socios',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'HAS',
            accessor: 'org_superficie',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            accessor: 'org_estimacion_cosecha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
        ],
      },
      //COLUMNAS CONDICIONADAS A MAYACERT
      ...(auth.getCertification() === 'mayacert'
        ? [
            {
              Header: 'TRANSICIÓN 3',
              columns: [
                {
                  Header: 'SOCIOS',
                  accessor: 't3_socios',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value}></LabelNumber>;
                  },
                },
                {
                  Header: 'HAS',
                  accessor: 't3_superficie',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value} digits={2}></LabelNumber>;
                  },
                },
                {
                  Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
                  accessor: 't3_estimacion_cosecha',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value}></LabelNumber>;
                  },
                },
              ],
            },
          ]
        : []),
      {
        Header: 'TRANSICIÓN 2',
        columns: [
          {
            Header: 'SOCIOS',
            accessor: 't2_socios',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'HAS',
            accessor: 't2_superficie',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            accessor: 't2_estimacion_cosecha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'TRANSICIÓN 1',
        columns: [
          {
            Header: 'SOCIOS',
            accessor: 't1_socios',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'HAS',
            accessor: 't1_superficie',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            accessor: 't1_estimacion_cosecha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
        ],
      },
      //COLUMNAS CONDICIONADAS A CERTIMEX
      ...(auth.getCertification() === 'mayacert'
        ? []
        : [
            {
              Header: 'TRANSICIÓN 0',
              columns: [
                {
                  Header: 'SOCIOS',
                  accessor: 't0_socios',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value}></LabelNumber>;
                  },
                },
                {
                  Header: 'HAS',
                  accessor: 't0_superficie',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value} digits={2}></LabelNumber>;
                  },
                },
                {
                  Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
                  accessor: 't0_estimacion_cosecha',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value}></LabelNumber>;
                  },
                },
              ],
            },
          ]),
      //COLUMNAS CONDICIONADAS A MAYACERT
      ...(auth.getCertification() === 'mayacert'
        ? [
            {
              Header: 'CONVENCIONAL',
              columns: [
                {
                  Header: 'SOCIOS',
                  accessor: 'con_socios',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value}></LabelNumber>;
                  },
                },
                {
                  Header: 'HAS',
                  accessor: 'con_superficie',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value} digits={2}></LabelNumber>;
                  },
                },
                {
                  Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
                  accessor: 'con_estimacion_cosecha',
                  sortable: true,
                  minWidth: 50,
                  Cell: ({ value }) => {
                    return <LabelNumber value={value}></LabelNumber>;
                  },
                },
              ],
            },
          ]
        : []),
      {
        Header: 'SANCIONADOS',
        columns: [
          {
            Header: 'SOCIOS',
            accessor: 'sancionados_socios',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'HAS',
            accessor: 'sancionados_superficie',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            accessor: 'sancionados_estimacion_cosecha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'BAJAS',
        columns: [
          {
            Header: 'SOCIOS',
            accessor: 'bajas_socios',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'TOTALES',
        columns: [
          {
            Header: 'SOCIOS',
            accessor: 'totales_socios',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'HAS',
            accessor: 'totales_superficie',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            accessor: 'totales_estimacion_cosecha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
        ],
      },
    ],
    [auth]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'localidad_clave', label: 'CLAVE' },
      { value: 2, target: 'id_localidad', label: 'CÓDIGO COMUNIDAD' },
      { value: 3, target: 'localidad', label: 'COMUNIDAD' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'org_socios', label: 'ORGÁNICOS -> SOCIOS' },
      { value: 7, target: 'org_superficie', label: 'ORGÁNICOS -> HAS' },
      {
        value: 8,
        target: 'org_estimacion_cosecha',
        label: 'ORGÁNICOS -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
      },
      //COLUMNAS CONDICIONADAS A MAYACERT
      ...(auth.getCertification() === 'mayacert'
        ? [
            { value: 9, target: 't3_socios', label: 'TRANSICIÓN 3 -> SOCIOS' },
            { value: 10, target: 't3_superficie', label: 'TRANSICIÓN 3 -> HAS' },
            {
              value: 11,
              target: 't3_estimacion_cosecha',
              label: 'TRANSICIÓN 3 -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            },
          ]
        : []),
      { value: 12, target: 't2_socios', label: 'TRANSICIÓN 2 -> SOCIOS' },
      { value: 13, target: 't2_superficie', label: 'TRANSICIÓN 2 -> HAS' },
      {
        value: 14,
        target: 't2_estimacion_cosecha',
        label: 'TRANSICIÓN 2 -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
      },
      { value: 15, target: 't1_socios', label: 'TRANSICIÓN 1 -> SOCIOS' },
      { value: 16, target: 't1_superficie', label: 'TRANSICIÓN 1 -> HAS' },
      {
        value: 17,
        target: 't1_estimacion_cosecha',
        label: 'TRANSICIÓN 1 -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
      },
      //COLUMNAS CONDICIONADAS A CERTIMEX
      ...(auth.getCertification() === 'mayacert'
        ? []
        : [
            { value: 18, target: 't0_socios', label: 'TRANSICIÓN 0 -> SOCIOS' },
            { value: 19, target: 't0_superficie', label: 'TRANSICIÓN 0 -> HAS' },
            {
              value: 20,
              target: 't0_estimacion_cosecha',
              label: 'TRANSICIÓN 0 -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            },
          ]),

      //COLUMNAS CONDICIONADAS A MAYACERT
      ...(auth.getCertification() === 'mayacert'
        ? [
            { value: 21, target: 'con_socios', label: 'CONVENCIONAL -> SOCIOS' },
            { value: 22, target: 'con_superficie', label: 'CONVENCIONAL -> HAS' },
            {
              value: 23,
              target: 'con_estimacion_cosecha',
              label: 'CONVENCIONAL -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
            },
          ]
        : []),
      { value: 24, target: 'sancionados_socios', label: 'SANCIONADOS -> SOCIOS' },
      { value: 25, target: 'sancionados_superficie', label: 'SANCIONADOS -> HAS' },
      { value: 26, target: 'bajas_socios', label: 'BAJAS -> SOCIOS' },
      { value: 27, target: 'totales_socios', label: 'TOTALES -> SOCIOS' },
      { value: 28, target: 'totales_superficie', label: 'TOTALES -> HAS' },
      {
        value: 29,
        target: 'totales_estimacion_cosecha',
        label: 'TOTALES -> SUMA DE ESTIMACIÓN DE COSECHA TOTAL (INSPECTOR)',
      },
    ],
    [auth]
  );

  const setFilter = value => {
    setCiclo(value.target);

    setBlocking(true);

    Api.search('tx_padron_concentrado', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i === 1) {
      return 'rgba(240, 224, 207, 0.5)';
    } else {
      if (auth.getCertification() === 'mayacert') {
        //ESTILOS CONDICIONADOS A MAYACERT
        switch (j) {
          case 0:
            return env.colors.neutral_600;
          case 1:
            return '#4A6700'; //Orgánico
          case 2:
            return '#7F8C8D'; //Transición 3
          case 3:
            return '#C7AE34'; //Transición 2
          case 4:
            return '#E49D2A'; //Transición 1
          case 5:
            return '#8E44AD'; //Convencional
          case 7:
            return '#91918A'; //Bajas
          default:
            return env.colors.neutral_600;
        }
      } else {
        //ESTILOS CONDICIONADOS A CERTIMEX
        switch (j) {
          case 0:
            return env.colors.neutral_600;
          case 1:
            return '#4A6700'; //Orgánico
          case 2:
            return '#C7AE34'; //Transición 2
          case 3:
            return '#E49D2A'; //Transición 1
          case 4:
            return '#D87748'; //Transición 0
          case 6:
            return '#91918A'; //Bajas
          default:
            return env.colors.neutral_600;
        }
      }
    }
  };

  const getColor = i => {
    return i === 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/padron">Padrón</Link>
          </li>
          <li className="breadcrumb-item active">Padrón concentrado</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('PADRON_CONCENTRADO_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/${report}/${ciclo?.uuid}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <Pagination
          blocking={blocking}
          columns={columns}
          data={data}
          pagination={true}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></Pagination>
      </CardContainer>
    </div>
  );
};

export default PadronConcentradoList;
