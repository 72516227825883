import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';
import NVD3Chart from 'react-nvd3';
import d3 from 'd3';

import Pagination from '../../components/pagination/pagination';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

const AcopioXProductorList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);

  const [totales, setTotales] = useState([]);
  const [totalAvance, setTotalAvance] = useState([]);
  const [entregasXCA, setEntregasXCA] = useState([
    {
      values: [],
    },
  ]);
  const [chartOptions, setChartOptions] = useState({
    noData: '',
  });
  const chartBarOptions = {
    noData: '',
    color: ['#C7AE34'],
    showLabels: true,
    showLegend: false,
    showControls: false,
    showValues: true,
    showYAxis: false,
    wrapLabels: true,
    // staggerLabels: true,
    rotateLabels: 45,
    valueFormat: d3.format(',.2f'),
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'ID YA',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CLAVE TX',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ row, value }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: 'ENTREGAS TOTALES',
        columns: [
          {
            Header: 'TOTAL ENTREGAS',
            accessor: 'entregas_no',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'Nº RECIPIENTES',
            accessor: 'entregas_no_bultos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'PESO NETO (KGS)',
            accessor: 'entregas_peso_neto_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'ESTIMACIÓN COSECHA',
        columns: [
          {
            Header: 'PESO ESTIMADO TOTAL (KGS)',
            accessor: 'est_peso_estimado_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: '% ENTREGADO VS. ESTIMACION',
            accessor: 'est_entregado_vs_estimacion',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'PAGOS TOTALES',
        columns: [
          {
            Header: 'PRECIO A PRODUCTOR ($/KG)',
            accessor: 'pagos_precio_por_kg',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'IMPORTE TOTAL ($)',
            accessor: 'pagos_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'DESCUENTOS DEUDAS',
            accessor: 'pagos_descuentos_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'PAGADO A PRODUCTOR ($)',
            accessor: 'pagos_pagado_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO DE ENTREGAS ($)',
            accessor: 'pagos_saldo_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'CRÉDITOS',
        columns: [
          {
            Header: 'Nº CRÉDITOS',
            accessor: 'creditos_no',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'CRÉDITO PRECOSECHA ($)',
            accessor: 'creditos_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'EFECTIVO ABONADO ($)',
            accessor: 'creditos_abonado_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO CRÉDITO ($)',
            accessor: 'creditos_saldo_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
        ],
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },

      { value: 1, target: 'productor_clave_tx', label: 'ID YA' },
      { value: 2, target: 'productor_codigo_tx', label: 'CLAVE TX' },
      { value: 4, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 5, target: 'localidad', label: 'LOCALIDAD' },
      { value: 6, target: 'region', label: 'REGIÓN' },
      { value: 7, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 8, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      { value: 9, target: 'entregas_no', label: 'TOTAL ENTREGAS' },
      { value: 10, target: 'entregas_no_bultos', label: 'Nº RECIPIENTES' },
      { value: 13, target: 'entregas_peso_neto_total', label: 'PESO NETO (KGS)' },

      { value: 15, target: 'est_peso_estimado_total', label: 'PESO ESTIMADO TOTAL (KGS)' },
      { value: 16, target: 'est_entregado_vs_estimacion', label: '% ENTREGADO VS. ESTIMACION' },

      { value: 17, target: 'pagos_precio_por_kg', label: 'PRECIO A PRODUCTOR (PERG)' },
      { value: 18, target: 'pagos_total', label: 'IMPORTE TOTAL ($)' },
      { value: 19, target: 'pagos_descuentos_total', label: 'DESCUENTOS DEUDAS' },
      { value: 20, target: 'pagos_pagado_total', label: 'PAGADO A PRODUCTOR ($)' },
      { value: 21, target: 'pagos_saldo_total', label: 'SALDO DE LA ENTREGA ($)' },

      { value: 22, target: 'creditos_no', label: 'Nº CRÉDITOS' },
      { value: 23, target: 'creditos_total', label: 'CRÉDITO PRECOSECHA ($)' },
      { value: 24, target: 'creditos_abonado_total', label: 'EFECTIVO ABONADO ($)' },
      { value: 25, target: 'creditos_saldo_total', label: 'SALDO CRÉDITO ($)' },
    ],
    []
  );

  const setFilter = value => {
    setCiclo(value.target);

    setBlocking(true);
    Api.Acopio.search('acopio_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);

        var total_kgs_est_totales = response.data
          .filter(function(item) {
            return item.est_peso_estimado_total != null;
          })
          .map(function(item) {
            return parseFloat(item.est_peso_estimado_total);
          })
          .reduce(function(previous, current) {
            return previous + current;
          }, 0);

        var total_kgs_ent = response.data
          .filter(function(item) {
            return item.entregas_peso_neto_total != null;
          })
          .map(function(item) {
            return parseFloat(item.entregas_peso_neto_total);
          })
          .reduce(function(previous, current) {
            return previous + current;
          }, 0);

        //TEST
        // let total_kgs_ent = 0;	let total_kgs_est_totales = 0; // => 0%
        // let total_kgs_ent = 0;	let total_kgs_est_totales = 100; // => 100%
        // let total_kgs_ent = 200;	let total_kgs_est_totales = 0; // => 100% - (200%)
        // let total_kgs_ent = 200;	let total_kgs_est_totales = 50; // => 100% - (400%)
        // let total_kgs_ent = 200;	let total_kgs_est_totales = 200; // => 100%
        // let total_kgs_ent = 200;	let total_kgs_est_totales = 400; // => 50%

        let total_avance = 0;
        let total_kgs_por_entregar = 0;
        if (total_kgs_ent == 0 && total_kgs_est_totales == 0) {
          // CEROS
        } else if (total_kgs_ent >= total_kgs_est_totales) {
          // avance_entregas = (total_kgs_est_totales != 0) ? parseInt((total_kgs_ent * 100) / total_kgs_est_totales): total_kgs_ent;
          total_avance = 100;
        } else {
          total_avance = parseInt((total_kgs_ent * 100) / total_kgs_est_totales);
          total_kgs_por_entregar = total_kgs_est_totales - total_kgs_ent;
        }

        setTotales([
          { label: 'Kgs entregados', value: total_kgs_ent, color: '#BC8268' },
          { label: 'Kgs por entregar', value: total_kgs_por_entregar, color: '#FFEED9' },
          { label: 'Kgs estimados totales', value: total_kgs_est_totales, color: '#EFEFEF' },
        ]);

        setChartOptions({
          title: total_avance + '%',
          donut: true,
          noData: '',
          growOnHover: false,
          margin: { left: 0, right: 0, top: 0, bottom: 0 },
          donutRatio: 0.6,
          showLegend: false,
          showLabels: false,
          // labelType: "percent",
          labelFormat: d3.format(',.2f'),
        });

        setTotalAvance([
          { label: 'Kgs por entregar', value: total_kgs_por_entregar, color: '#FFEED9' },
          { label: 'Kgs entregados', value: total_kgs_ent, color: '#BC8268' },
        ]);

        var centros_acopio_ent = [];
        response.data
          .filter(function(item) {
            return item.id_centro_acopio != null;
          })
          .reduce(function(res, value) {
            if (!res[value.id_centro_acopio]) {
              res[value.id_centro_acopio] = { id: value.id_centro_acopio, label: value.centro_acopio, value: 0 };
              centros_acopio_ent.push(res[value.id_centro_acopio]);
            }
            res[value.id_centro_acopio].value += parseFloat(value.entregas_peso_neto_total);
            return res;
          }, {});

        var centros_acopio_pen = [];
        setEntregasXCA([
          {
            key: 'Entregado',
            color: '#C7AE34',
            // showValues: true,
            values: centros_acopio_ent,
          },
          {
            key: 'Por entregar',
            color: '#FFEDCC',
            values: centros_acopio_pen,
          },
        ]);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgba(240, 224, 207, 0.5)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Por productor</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <div className="row">
        <div className="col-xl-12 px-0">
          <CardContainer height={'100%'}>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataFilter(evt);
                  }}></SearchColumn>
              </div>
              <div className="col-2">
                {ciclo?.uuid && auth.hasPermission('ACOPIOS_X_PRODUCTOR_EXPORTAR') && (
                  <div className="d-flex justify-content-end">
                    <LinkDownload
                      url={`xls/acopios_x_productor/${ciclo?.uuid}`}
                      label="Descargar Excel"
                      icon={env.images.icon.xls}></LinkDownload>
                  </div>
                )}
              </div>
            </div>
            <Pagination
              blocking={blocking}
              columns={columns}
              data={data}
              pagination={true}
              fnBackgroundColor={getBackgroundColor}
              fnColor={getColor}></Pagination>
          </CardContainer>
        </div>
      </div>

      <div className="row text-center pt-4">
        <div className="col-xl-5 pr-4">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <Panel>
              <PanelBody className="p-0">
                <div
                  className="row"
                  style={{
                    height: 320,
                    backgroundColor: 'white',
                    borderRadius: '20px 20px 20px 20px',
                    padding: '20px',
                  }}>
                  <h6 className="font-lato-normal-400 w-100 text-center">Café entregado vs. estimación de cosecha</h6>
                  <div className="col-xl-8 col-md-8">
                    <NVD3Chart
                      type="pieChart"
                      datum={totalAvance}
                      height={280}
                      options={chartOptions}
                      x="label"
                      y="value"
                      label="percent"
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div className="col-xl-4 col-md-4 text-left pt-3">
                    {totales.map((item, i) => {
                      return (
                        <div key={i} className="row">
                          <div className="col-xl-3 col-md-3">
                            <i className="fa fa-square pt-2" style={{ color: item.color }}></i>
                          </div>
                          <div className="col-xl-9 col-md-9">
                            <h1 className="font-lato-normal-600" style={{ fontSize: '18px' }}>
                              {item.value.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h1>
                            <h6 className="font-lato-normal-400 pb-4">{item.label}</h6>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </BlockUi>
        </div>
        <div className="col-xl-7">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <Panel>
              <PanelBody className="p-0">
                <div
                  className="row"
                  style={{
                    height: 320,
                    backgroundColor: 'white',
                    borderRadius: '20px 20px 20px 20px',
                    padding: '20px',
                  }}>
                  <h6 className="font-lato-normal-400 w-100 text-center">Café entregado por centro de acopio</h6>
                  <div className="col-xl-12 col-md-12">
                    <NVD3Chart
                      datum={entregasXCA}
                      type="discreteBarChart"
                      options={chartBarOptions}
                      height="240"
                      x="label"
                      y="value"
                    />
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default AcopioXProductorList;
