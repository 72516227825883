import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import ModalEfectivo from '../../components/modal/modal-efectivo.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import Pagination from '../../components/pagination/pagination';
import SearchColumn from '../../components/search/search-column.jsx';

import { subscribe, unsubscribe } from '../../components/events';

const EntregasEfectivoList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const [edit, setEdit] = useState(null);
  const dialog = createRef();
  const onClickEdit = (event, data) => {
    event.preventDefault();
    setEdit(data);
  };

  const onShowModal = () => dialog.current.add();

  useEffect(() => {
    if (edit !== null) {
      dialog.current.edit(edit);
    }
  }, [edit]);

  const baseColumns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN GENERAL',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'nombre',
            sortable: true,
            minWidth: 150,
          },
          {
            Header: 'DESEMBOLSO EFECTIVO',
            accessor: 'desembolsos_sum_cantidad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'TOTAL PAGADO A PRODUCTORES',
            accessor: 'acopio_pagos_sum_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO',
            accessor: 'saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
        ],
      },
    ],
    []
  );

  const [dynamicColumns, setDynamicColumns] = useState(baseColumns);

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'CENTRO DE ACOPIO' },
      { value: 2, target: 'desembolsos_sum_cantidad', label: 'DESEMBOLSO EFECTIVO' },
      { value: 3, target: 'acopio_pagos_sum_total', label: 'TOTAL PAGADO A PRODUCTORES' },
      { value: 4, target: 'saldo', label: 'SALDO' },
    ],
    []
  );

  const updateData = () => {
    setBlocking(true);
    Api.Acopio.search('efectivo_por_centro_acopio', {
      filter: [{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }],
    })
      .then(response => {
        let maxColumns = response.data
          .map(item => item.desembolsos.length)
          .reduce((current, sum) => (current > sum ? current : sum), 0);

        var columns = [];
        for (var i = 0; i < maxColumns; i++) {
          columns.push({
            Header: 'DESEMBOLSO ' + (i + 1),
            columns: [
              {
                Header: 'FECHA',
                accessor: 'desembolsos[' + i + '].fecha_envio',
                sortable: true,
                minWidth: 80,
                Cell: ({ value }) => {
                  return <LabelDate value={value}></LabelDate>;
                },
              },
              {
                Header: 'MONTO',
                accessor: 'desembolsos[' + i + '].monto',
                sortable: true,
                minWidth: 50,
                Cell: ({ value }) => {
                  return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
                },
              },
              {
                Header: 'MOTIVO DESEMBOLSO DE EFECTIVO',
                accessor: 'desembolsos[' + i + ']',
                id: i + '_3', //USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 100,
                Cell: ({ value }) => {
                  let v = value;
                  if (v != null) return <LabelColor label={v.motivo} color={v.motivo_color}></LabelColor>;
                  else return '';
                },
              },
              {
                Header: 'NOMBRE DE ACOPIADOR',
                accessor: 'desembolsos[' + i + '].usuario_acopiador.nombre',
                sortable: true,
                minWidth: 200,
              },
              {
                Header: 'REGISTRO',
                accessor: 'desembolsos[' + i + ']',
                id: i + '_5', //USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 350,
                Cell: cell => {
                  let v = cell.value;
                  if (v != null) return `Registrado por ${v.usuario_registro.nombre} el ${v.created_at.substr(0, 10)}`;
                  else return '';
                },
              },
              {
                Header: 'RECIBO',
                accessor: 'desembolsos[' + i + '].recibo_pdf',
                id: i + '_6', ///USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 50,
                Cell: ({ value }) => {
                  let v = value;
                  if (v != null && v != '')
                    return (
                      <a href={`${env.assetURL}/${v}`} target="_blank" rel="noreferrer">
                        <img src={env.images.icon.pdf} className="pr-1" /> <span>Descargar</span>
                      </a>
                    );
                  else return '';
                },
              },
              {
                Header: '',
                accessor: 'desembolsos[' + i + ']',
                id: i + '_7', ///USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 50,
                Cell: ({ value }) => {
                  let v = value;
                  if (v != null && auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_ELIMINAR'))
                    return (
                      <a onClick={e => onClickEdit(e, v)} className="text-success m-r-2">
                        <span className="text-success">
                          <i className="fa fa-pencil-alt"></i> Editar
                        </span>
                      </a>
                    );
                  else return '';
                },
              },
            ],
          });
        }

        setDynamicColumns([...baseColumns, ...columns]);

        setData(response.data);
        setOriginalData(response.data);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  };

  useEffect(() => {
    updateData();

    subscribe('Efectivo::onSave', e => handleSave(e));

    return () => {
      unsubscribe('Efectivo::onSave');
    };
  }, []);

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i === 1) {
      return 'rgba(240, 224, 207, 0.5)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i === 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el desembolso de efectivo ${event.detail.id}.`,
    });

    updateData();
  };

  return (
    <div>
      <ModalEfectivo ref={dialog} title="desembolso de efectivo"></ModalEfectivo>

      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Efectivo</li>
        </ol>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/efectivo_cooperativa/${auth.user.id_cooperativa}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <Pagination
          blocking={blocking}
          selected="id"
          columns={dynamicColumns}
          data={data}
          pagination={true}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></Pagination>

        <div className="row">
          <div className="col-3">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_NUEVO') && (
              <button
                onClick={onShowModal}
                type="button"
                className="btn btn-sirio"
                style={{ borderRadius: '20px 20px 20px 20px' }}>
                <i className="fa fa-plus"></i> Registrar nuevo
              </button>
            )}
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasEfectivoList;
