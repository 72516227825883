import { useEffect, useState } from 'react';
import env from '../environment';
import { useAuth } from '../components/content/auth';

const useLoadBotman = () => {
  let [loadingBot, setLoadingBot] = useState('loading');
  const url = 'https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/js/widget.js';

  const { user } = useAuth();

  useEffect(() => {
    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = e => {
      setLoadingBot(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      const botmanWidget = {
        chatServer: env.assetURL + '/botman',
        frameEndpoint: env.assetURL + '/botman/chat',
        title: 'Hola',
        introMessage: 'Bienvenidos/as al chat de Inteligencia Artificial de Sirio. ¿cómo puedo ayudarte?',
        placeholderText: '... envía tu mensaje',
        aboutText: 'Siriobot',
        userId: user.id,
        bubbleAvatarUrl: 'https://robohash.org/chatbot',
      };
      window.botmanWidget = botmanWidget;

      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
    };
  }, [url, user]);

  return { loadingBot };
};

export default useLoadBotman;
