const Images = {
  logo: require('../assets/images/logo.svg'),
  spinners: {
    building: require('../assets/images/building.gif'),
  },
  login: {
    bg_inicio_back: require('../assets/images/bg-inicio-back.png'),
    bg_inicio_front: require('../assets/images/bg-inicio-front.png'),
  },
  profile: {
    productor: require('../assets/images/productor.png'),
  },
  icon: {
    user: require('../assets/images/icons/User_gray.svg'),
    map: require('../assets/images/icons/Map_gray.svg'),
    pdf: require('../assets/images/icons/Pdf.svg'),
    xls: require('../assets/images/icons/Xls.svg'),
    north: require('../assets/images/icons/north-icon.svg'),
    arrow: require('../assets/images/icons/arrowIcon.svg'),
    comunidad: require('../assets/images/icons/comunidad.svg'),
    sede: require('../assets/images/icons/sede.svg')
  },
  card: {
    p: require('../assets/images/cards/Card_P.svg'),
    pp: require('../assets/images/cards/Card_PP.svg'),
    pa: require('../assets/images/cards/Card_PA.svg'),
    pd: require('../assets/images/cards/Card_PD.svg'),
    po: require('../assets/images/cards/Card_PO.svg'),
    circulo_A46B00: require('../assets/images/cards/circulo_A46B00.svg'),
    circulo_BC8268: require('../assets/images/cards/circulo_BC8268.svg'),
    circulo_E49D2A: require('../assets/images/cards/circulo_E49D2A.svg'),
    circulo_FFEED9: require('../assets/images/cards/circulo_FFEED9.svg')
  },
  acopio: {
    entregado: require('../assets/images/acopios/entregado.svg'),
    disponible: require('../assets/images/acopios/disponible.png'),
    rechazado: require('../assets/images/acopios/rechazado.svg'),
  },
  user_default: require('../assets/images/user-default.svg'),
};

export { Images };
