import React from 'react';

class LabelNumber extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		
		let suffix = this.props.suffix || '';
		let digits = this.props.digits || 0;
		let isCurrency = this.props.currency || false;
		let value = '';
		if(this.props.value != null){			
			let v = parseFloat(this.props.value) || 0;
			value = (isCurrency) ? v.toLocaleString('en-US', {style:'currency', currency:'USD', minimumFractionDigits: digits, maximumFractionDigits: digits}): v.toLocaleString('en-US', {minimumFractionDigits: digits, maximumFractionDigits: digits})
		}
		return (
			<p className='text-right' style={{margin: 0}}>
				{value}{suffix} 
			</p>
		);
	}

}

export default LabelNumber;