const Colors = {

  surface: '#FCFCFC', //EN USO

  primary_50: '#FAFFDF',
  primary_300: '#91B833',
  primary_500: '#789D16',
  primary_600: '#4A6700', //EN USO
  primary_700: '#374E00',
  primary_900: '#131F00', //EN USO

  secondary_50: '#FCFCFC',
  secondary_100: '#FFDDB0',
  secondary_200: '#FFB94C', //EN USO
  secondary_300: '#E49D2A',
  secondary_400: '#C58307',
  secondary_500: '#A46B00', //EN USO
  secondary_700: '#643F00',
  secondary_900: '#2A1800',

  neutral_1: '#F6F7F5',
  neutral_2: '#F2F1E8', //EN USO
  neutral_3: '#FFEED9',

  neutral_10: '#FCFCFC',
  neutral_50: '#F2F1E8',
  neutral_100: '#E4E3DB',
  neutral_200: '#C7C7BF',
  neutral_300: '#ACACA4',
  neutral_400: '#91918A',
  neutral_500: '#777771',
  neutral_600: '#5F5F59',
  neutral_700: '#464741', //EN USO
  neutral_800: '#30312B',
  neutral_900: '#1B1C17',

  black: '#000000',

  black_high: 'rgba(0, 0, 0, 0.87)',
  black_medium: 'rgba(0, 0, 0, 0.6)',
  black_12: 'rgba(0, 0, 0, 0.12)', //EN USO
  black_05: 'rgba(0, 0, 0, 0.05)', //EN USO

  error: '#BA1B1B', //EN USO

};

export { Colors };