
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Api from '../../api/global.service';
import FormMinSelectApi from '../../nuup/componentes/Forms/FormMinSelectApi.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ExampleCustomInput from './input';
import * as yup from "yup";
import Moment from 'moment';


const ModalAnticipo = forwardRef((props, ref) => {
	
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	
	const [fecha, setFecha] = useState(null);
	const [idCentroAcopio, setIdCentroAcopio] = useState('');
	const [cantidad, setCantidad] = useState('');
	const [idAcopiador, setIdAcopiador] = useState('');
	const [idCiclo, setIdCiclo] = useState(0);
	const [idAcopio, setIdAcopio] = useState('');

	const [errors, setErrors] = useState(null);
	const schema = yup.object().shape({		
		fecha: yup.string().transform(v => (v === null ? "" : v.toString())).required('Debe seleccionar una fecha'),
		id_centro_acopio: yup.string().required('Debe seleccionar un centro de acopio'),
		cantidad: yup.number().required('Debe escribir una cantidad').positive(),
		id_acopiador: yup.string().required('Debe seleccionar un acopiador'),
	})

	//To call outside
	useImperativeHandle(ref, () => ({
    show: showHandler,
		setIdCiclo: (id) => setIdCiclo(id),
		setIdAcopio: (id) => setIdAcopio(id)
  }));

	const showHandler = () => {
		setShow(true);
		setFecha(null);
		setIdCentroAcopio('');
		setCantidad('');
		setIdAcopiador('');
		setErrors(null);
	}

	const closeHandler = () => {
		setShow(false);		
	}

	const validateHandler = () => {

		var data = {			 
			fecha: fecha,
			id_centro_acopio: idCentroAcopio,
			cantidad: cantidad,
			id_acopiador: idAcopiador,
		}	
		var that = this;		
		schema.validate(data, { abortEarly: false })
			.then(function (valid) {

				//Datos validos
				const data = {
					id_acopio: idAcopio, 					
					fecha: Moment(fecha).format('yyyy-MM-DD'), 
					id_centro_acopio: idCentroAcopio, 
					cantidad: cantidad, 
					id_acopiador: idAcopiador, 
					id_ciclo: idCiclo
				}

			})	
			.catch(function (err) {
				let errors = err.inner.reduce(function(map, obj) {
					map[obj.path] = obj.message;
					return map;
				}, {});
				setErrors(errors);
			});
		
	}

	return (												
		<div className="modal" style={{display: (show) ? 'block': 'none', backgroundColor: 'rgba(0,0,0,0.2)'}}>
			<div className="modal-dialog" style={{top: 50, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)'}}>
				<div className="modal-content" style={{height: '100%'}}>
					<div className="modal-header" style={{flex: 'inherit'}}>
						<h4 className="modal-title">{props.title}</h4>
						<button onClick={() => closeHandler()} type="button" className="btn" style={{borderRadius: '20px 20px 20px 20px'}}>
							<i className="fa fa-times"></i>
						</button>
					</div>
					<div className="modal-body">
						
						<div className="row my-4">
							<div className="col-12">
								<DatePicker customInput={<ExampleCustomInput />} dateFormat={"dd/MMM/yyyy"} selected={fecha} onChange={(date) => { setFecha(date) }} wrapperClassName="w-100"/>
								{errors?.fecha && ( 
										<div className="invalid-feedback">{errors?.fecha}</div>
									)
								}
							</div>											
						</div>	

						<div className="row my-4">
							<div className="col-12">
								<FormMinSelectApi id="id_centro_acopio" name="id_centro_acopio" placeholder="Seleccione un centro de acopio" url="centros_acopios" keyArray="id" labelArray="nombre" showLoading={false} onChange={(e) => {setIdCentroAcopio(e.target.value)}} />
								{errors?.id_centro_acopio && ( 
										<div className="invalid-feedback">{errors?.id_centro_acopio}</div>
									)
								}
							</div>																																													
						</div>	

						<div className="row my-4">
							<div className="col-12">
								<div className="input-group">
									<div className="input-group-prepend">
										<i className="fa fa-search" style={{padding: 14, color: '#ffffff'}}></i>
									</div>
									<input type="number" name="cantidad" className="form-control" placeholder="Escriba un monto" value={cantidad} onChange={(event) => { setCantidad(event.target.value) }}/>
									<div className="input-group-append">
										<i className="fa fa-chevron-down" style={{padding: 14, color: '#ffffff'}}></i>
									</div>
								</div>
								{errors?.cantidad && ( 
										<div className="invalid-feedback">{errors?.cantidad}</div>
									)
								}
							</div>																																													
						</div>

						<div className="row my-4">
							<div className="col-12">
								<FormMinSelectApi id="id_acopiador" name="id_acopiador" placeholder="Seleccione un acopiador" url="users" keyArray="id" labelArray="nombre" showLoading={false} onChange={(e) => {setIdAcopiador(e.target.value)}} />
								{errors?.id_acopiador && ( 
										<div className="invalid-feedback">{errors?.id_acopiador}</div>
									)
								}
							</div>																																													
						</div>

					</div>
					<div className="modal-footer">
						{ blocking && (
							<div className="spinner-border text-warning" role="status">
								<span className="sr-only">Guardando...</span>
							</div>
						)}
						{ !blocking && (
							<>
								<button onClick={() => closeHandler()} type="button" className="btn btn-outline-secondary" style={{borderRadius: '20px 20px 20px 20px'}}>
									<i className="fa fa-times"></i> Cancelar
								</button>
								<button onClick={() => validateHandler()} type="button" className="btn btn-outline-secondary btn-sirio" style={{borderRadius: '20px 20px 20px 20px'}}>
									<i className="fa fa-save"></i> Guardar anticipo
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);

});

export default ModalAnticipo;