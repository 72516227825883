import React, { useState, useEffect } from 'react';
import SelectColumn from '../select/select-column';

const SearchColumn = ({ data, columns, setDataFilter }) => {
  const [column, setColumn] = useState({});
  const [search, setSearch] = useState('');
  const [compareColumns, setCompareColumns] = useState({});

  useEffect(() => {
    //Filtrar todas las columnas para comparar los datos
    let c = columns.filter(item => {
      return item.target !== '';
    });
    setCompareColumns(c);
  }, []);

  const replaceDiacritics = str => {
    var diacritics = [
      { char: 'a', base: /[\340-\346]/g },
      { char: 'e', base: /[\350-\353]/g },
      { char: 'i', base: /[\354-\357]/g },
      { char: 'o', base: /[\362-\370]/g },
      { char: 'u', base: /[\371-\374]/g },
    ];

    diacritics.forEach(function(letter) {
      str = str.replace(letter.base, letter.char);
    });

    return str;
  };

  const matchString = (str, match) => {
    let _str = new String(str);
    return replaceDiacritics(_str.toLowerCase()).includes(match);
  };

  const setFilter = value => {
    let filter_data = data.filter(item => {
      let s = replaceDiacritics(value.search.toLowerCase());
      if (s !== '') {
        if (value.target.target === '') {
          let found = compareColumns.reduce(function(f, column) {
            let res = false;
            if (item[column.target] !== null) {
              res = matchString(item[column.target], s);
            }
            return f || res;
          }, false);

          if (found) return item;
          else return null;
        } else {
          //Filtrar por columna
          let res = false;
          if (item[value.target.target] !== null) {
            res = matchString(item[value.target.target], s);
          }
          if (res) return item;
          else return null;
        }
      } else return item;
    });

    setDataFilter(filter_data);
  };

  const handlerChange = s => {
    setSearch(s);
    setFilter({ search: s, target: column });
  };

  const columnFilter = event => {
    setColumn(event.target);
    setFilter({ search: search, target: event.target });
  };

  const clearSearch = () => {
    setSearch('');
    setFilter({ search: '', target: column });
  };

  return (
    <div className="d-flex d-flex-columns pb-3" style={{ width: 500 }}>
      <SelectColumn
        columns={columns}
        setFilter={evt => {
          columnFilter(evt);
        }}></SelectColumn>
      <div className="input-group input-group-sm">
        <span className="input-group-text">
          <i className="fa fa-search"></i>
        </span>
        <input
          type="text"
          className="form-control"
          value={search}
          placeholder='Búsqueda'
          onChange={e => {
            handlerChange(e.target.value);
          }}
        />
        {search !== '' && (
          <span
            className="input-group-text"
            onClick={() => {
              clearSearch();
            }}>
            <i className="fa fa-times"></i>
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchColumn;
