import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { Link, useParams, useHistory } from 'react-router-dom';
import LinkDownload from '../../components/link/link-download';
import { PanelCollapse } from '../../components/panel/panel-collapse.jsx';
import CardBasic from '../../components/card/card-basic.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import BlockUi from 'react-block-ui';
import env from './../../environment.js';
import Moment from 'moment';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import Pagination from '../../components/pagination/pagination';
import generalCatchErrorsModal from '../../components/modal/modal-error';
import { useAuth } from '../../components/content/auth';

const CuentaProfile = () => {
  const history = useHistory();
  const { uuid } = useParams();
  const auth = useAuth();
  const [cuenta, setCuenta] = useState({});
  const [blocking, setBlocking] = useState(false);

  const [dataParcelas, setDataParcelas] = useState([]);
  const [blockingParcelas, setBlockingParcelas] = useState(false);

  const [dataAcopios, setDataAcopios] = useState([]);
  const [blockingAcopios, setBlockingAcopios] = useState(false);

  const [dataFormatos, setDataFormatos] = useState([]);
  const [blockingFormatos, setBlockingFormatos] = useState(false);

  const [dataEvaluaciones, setDataEvaluaciones] = useState([]);
  const [blockingEvaluaciones, setBlockingEvaluaciones] = useState(false);

  const bottomHeight = '100%';

  const [fotoURL, setFotoURL] = useState(null);
  const [fotoOpen, setFotoOpen] = useState(false);

  const columnsParcelas = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 65,
      },
      {
        Header: 'PARCELA',
        accessor: 'nombre',
        sortable: true,
        Cell: ({ value, row }) => {
          if (row.original.baja_parcela != null) {
            return (
              <>
                <p>
                  <s>{value} </s>
                </p>
                <p>
                  (baja por {row.original.baja_parcela} el{' '}
                  {Moment(row.original.baja_parcela_fecha).format('yyyy-MM-DD')} )
                </p>
              </>
            );
          } else {
            return <p>{value}</p>;
          }
        },
      },
      {
        Header: 'SUPERFICIE (HA)',
        accessor: 'superficie',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'N° DE PLANTAS',
        accessor: 'plantas',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
      {
        Header: 'VARIEDADES',
        accessor: 'variedades_str',
        sortable: true,
      },
      {
        Header: 'SOMBRA',
        accessor: 'sombra',
        sortable: true,
      },
      {
        Header: 'CROQUIS',
        accessor: 'croquis',
        sortable: true,
        minWidth: 60,
        Cell: ({ row }) => {
          return (
            <Link
              to={{ pathname: `/admin/parcelas/perfil/print/${row.original.uuid}` }}
              className="m-r-2"
              style={{ color: '#789D16' }}>
              <img src={env.images.icon.pdf} className="pr-1" /> <span>Ver croquis</span>
            </Link>
          );
        },
      },
    ],
    []
  );

  const columnsAcopios = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_acopio',
        sortable: false,
        width: 65,
      },
      {
        Header: 'FECHA DE ENTREGA',
        accessor: 'fecha_acopio',
        sortable: true,
        minWidth: 80,
        Cell: ({ value }) => {
          return <LabelDate value={value}></LabelDate>;
        },
      },
      {
        Header: 'PESO NETO (KGS)',
        accessor: 'peso_neto',
        sortable: true,
        minWidth: 50,
        Cell: ({ value }) => {
          return <LabelNumber value={value} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'PRECIO A PRODUCTOR ($/KG)',
        accessor: 'precio_por_kg',
        sortable: true,
        minWidth: 50,
        Cell: ({ value }) => {
          return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'IMPORTE TOTAL ($)',
        accessor: 'importe_total',
        sortable: true,
        minWidth: 50,
        Cell: ({ value }) => {
          return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <p style={{ margin: 0 }}>{value}</p>;
        },
      },
    ],
    []
  );

  const columnsFormatos = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 65,
      },
      {
        Header: 'FECHA DE LEVANTAMIENTO',
        accessor: 'fecha',
        sortable: true,
      },
      {
        Header: 'TIPO DE FORMATO',
        accessor: 'tipo',
        sortable: true,
      },
      {
        Header: 'DESCARGAR PDF',
        accessor: 'pdf',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <LinkDownload
              url={`pdf/${row.original.reporte}/${row.original.uuid}`}
              label="Descargar"
              icon={env.images.icon.pdf}></LinkDownload>
          );
        },
      },
      {
        Header: 'DESCARGAR EXCEL',
        accessor: 'xls',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <LinkDownload
              url={`xls/${row.original.reporte}/${row.original.uuid}`}
              label="Descargar"
              icon={env.images.icon.xls}></LinkDownload>
          );
        },
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <p style={{ margin: 0 }}>{value}</p>;
        },
      },
    ],
    []
  );

  const columnsEvaluaciones = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: '_no',
        sortable: false,
        width: 45,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
      {
        Header: 'CONDICIÓN',
        accessor: 'condicion_str',
        sortable: true,
      },
      // {
      //   Header: 'CONDICIONES TOTALES',
      //   accessor: 'cond_total',
      //   sortable: true,
      //   Cell: ({ value }) => {
      //     return <LabelNumber value={value}></LabelNumber>;
      //   },
      // },
      // {
      //   Header: 'CONCIDICIONES CUMPLIDAS',
      //   accessor: 'cond_cumplidas',
      //   sortable: true,
      //   Cell: ({ value }) => {
      //     return <LabelNumber value={value}></LabelNumber>;
      //   },
      // },
      {
        Header: 'FECHA DE AUTORIZACIÓN',
        accessor: 'fecha_aut',
        sortable: true,
      },
      {
        Header: 'AUTORIZÓ',
        accessor: 'user_auth',
        sortable: true,
      },
      {
        Header: 'NIVEL DE RIESGO',
        accessor: 'nivel_riesgo',
        sortable: true,
      },
      {
        Header: 'VER EVALUACIÓN',
        accessor: 'ver',
        sortable: true,
        Cell: ({ row }) => (
          <Link to={{ pathname: `/admin/comite/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <img src={env.images.icon.xls} className="pr-1" /> <span>Ver</span>
          </Link>
        ),
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <p style={{ margin: 0 }}>{value}</p>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);
      setBlockingParcelas(true);
      setBlockingEvaluaciones(true);

      setBlockingFormatos(true);
      if (env.appEnv !== 'prod') {
        setBlockingAcopios(true);
      }
      Api.get('cuentas', uuid)
        .then(response => {
          response.data = response.data.map(function(item) {
            return {
              ...item,
              _fecha_ingreso: item.fecha_ingreso != null ? item.fecha_ingreso.substr(0, 4) : null,
            };
          });

          setCuenta(response.data[0]);

          setBlocking(false);

          Api.get('parcelas_por_productor', uuid)
            .then(response => {
              response.data = response.data.map(function(item, i) {
                return {
                  ...item,
                  _no: i + 1,
                };
              });
              setDataParcelas(response.data);
              const fotoURL = response.data?.filter(p => p.newest_foto !== null)?.[0].newest_foto;
              setFotoURL(fotoURL);
              setBlockingParcelas(false);
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('e', e);
              setBlockingParcelas(false);
            });

          if (auth.hasPermission('ACOPIOS_DASHBOARD_VER')) {
            Api.Acopio.search('entregas_por_productor', {
              filter: [{ column: 'productor_id', operator: '=', value: response.data[0].id }],
              sort: [{ column: 'fecha_acopio', sort: 'desc' }],
            })
              .then(response => {
                response.data = response.data.map(function(item, i) {
                  return {
                    ...item,
                    _no: i + 1,
                  };
                });
                setDataAcopios(response.data);
                setBlockingAcopios(false);
              })
              .catch(e => {
                // eslint-disable-next-line no-console
                console.log('e', e);
                setBlockingAcopios(false);
              });
          }

          Api.search('tx_formatos', {
            filter: [{ column: 'id_cuenta', operator: '=', value: response.data[0].id }],
          })
            .then(response => {
              response.data = response.data.map(function(item, i) {
                return {
                  ...item,
                  _no: i + 1,
                };
              });
              setDataFormatos(response.data);
              setBlockingFormatos(false);
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('e', e);
              setBlockingFormatos(false);
            });

          Api.search('comite_evaluaciones', {
            filter: [{ column: 'id_cuenta', operator: '=', value: response.data[0].id }],
          })
            .then(response => {
              response.data = response.data.map(function(item, i) {
                return {
                  ...item,
                  _no: i + 1,
                };
              });
              setDataEvaluaciones(response.data);
              setBlockingEvaluaciones(false);
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('e', e);
              setBlockingEvaluaciones(false);
            });
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          generalCatchErrorsModal(e.response?.status, '/admin/cuentas/list');
          setBlocking(false);
          setBlockingParcelas(false);
          setBlockingAcopios(false);
          setBlockingFormatos(false);
          setBlockingEvaluaciones(false);
        });
    }
  }, []);

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/admin/cuentas">Listado productores</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/cuentas/list">Listado productores</Link>
            </li>
            <li className="breadcrumb-item active">Perfil productor</li>
          </ol>

          <Link to={{ pathname: '/admin/cuentas/list' }}>
            <button type="button" className="btn btn-outline-secondary" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-arrow-left"></i> Volver al listado
            </button>
          </Link>
        </Breadcrumb>

        <div className="row pb-4">
          <div className="col-lg-6  mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardContainer>
                <div className="row">
                  <div className="col-lg-5 col-sm-3 text-center">
                    {fotoURL ? (
                      <button
                        type="button"
                        className="btn"
                        style={{ borderRadius: '20px 20px 20px 20px' }}
                        onClick={() => setFotoOpen(!fotoOpen)}>
                        <img src={`${env.apiURL}/${fotoURL}`} className="profile_productor"></img>
                      </button>
                    ) : (
                      <img src={env.images.profile.productor} />
                    )}
                  </div>
                  <div className="col-lg-7 col-sm-9">
                    <div className="row" style={{ paddingTop: 16 }}>
                      <div className="col-sl-8">
                        <h6 className="font-lato-normal-400">Productor:</h6>
                        <h3 className="font-lato-normal-500 pb-3">{cuenta.nombre || '-'}</h3>
                        <LabelCategory
                          label={cuenta.cuenta_categoria}
                          color={cuenta.cuenta_categoria_color}></LabelCategory>
                      </div>
                      <div className="col-sl-4">
                        {(cuenta.clave_tx, cuenta.codigo_tx) ? (
                          <>
                            <h6 className="font-lato-normal-400">{cuenta.clave_tx}</h6>
                            <h3 className="font-lato-normal-400 pb-4">{cuenta.codigo_tx}</h3>
                          </>
                        ) : (
                          <>
                            <h6 className="font-lato-normal-400">-</h6>
                            <h3 className="font-lato-normal-400 pb-4">{cuenta.clave_tx || cuenta.codigo_tx || '-'}</h3>
                          </>
                        )}
                        {cuenta._fecha_ingreso != null && (
                          <h6 className="font-lato-normal-400">Desde {cuenta._fecha_ingreso}</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CardContainer>
            </BlockUi>
          </div>
          <div className="col-lg col-sm-4 mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic
                // height={topHeight}
                label="Parcelas"
                value={dataParcelas
                  .reduce((acc, p) => (!p.baja_parcela ? ++acc : acc), 0)
                  .toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}></CardBasic>
            </BlockUi>
          </div>
          <div className="col-lg col-sm-4 mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic
                // height={topHeight}
                label="Hectáreas"
                value={dataParcelas
                  .reduce((acc, p) => (!isNaN(parseFloat(p.superficie)) ? acc + parseFloat(p.superficie) : acc), 0)
                  .toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}></CardBasic>
            </BlockUi>
          </div>
          <div className="col-lg col-sm-4 mt-2">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <CardBasic
                // height={topHeight}
                label="Plantas"
                value={dataParcelas
                  .reduce((acc, p) => (!isNaN(parseInt(p.plantas)) ? acc + parseInt(p.plantas) : acc), 0)
                  .toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}></CardBasic>
            </BlockUi>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={bottomHeight}>
              <div className="row">
                <div className="col-xl-12">
                  <PanelCollapse title="PARCELAS DE CAFÉ">
                    <Pagination
                      blocking={blockingParcelas}
                      columns={columnsParcelas}
                      data={dataParcelas}
                      pagination={false}></Pagination>
                  </PanelCollapse>
                </div>
                {auth.hasPermission('ACOPIOS_DASHBOARD_VER') && (
                  <div className="col-xl-12">
                    <PanelCollapse title="ACOPIO">
                      <Pagination
                        blocking={blockingAcopios}
                        columns={columnsAcopios}
                        data={dataAcopios}
                        pagination={true}></Pagination>
                    </PanelCollapse>
                  </div>
                )}
                <div className="col-xl-12">
                  <PanelCollapse title="FORMATOS">
                    <Pagination
                      blocking={blockingFormatos}
                      columns={columnsFormatos}
                      data={dataFormatos}
                      pagination={true}></Pagination>
                  </PanelCollapse>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={bottomHeight}>
              <div className="row">
                <div className="col-xl-12">
                  <PanelCollapse title="COMITÉ DE EVALUACIÓN">
                    <Pagination
                      blocking={blockingEvaluaciones}
                      columns={columnsEvaluaciones}
                      data={dataEvaluaciones}
                      pagination={true}></Pagination>
                    <Link to={{ pathname: `/admin/cuentas/comite/${uuid}` }}>
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-4"
                        style={{ borderRadius: '20px 20px 20px 20px' }}>
                        <i className="fa fa-plus"></i> Añadir nueva evaluación
                      </button>
                    </Link>
                  </PanelCollapse>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>

        {fotoURL ? (
          <Modal isOpen={fotoOpen} toggle={() => setFotoOpen(!fotoOpen)} centered={true} size={'lg'}>
            <ModalBody>
              <img src={`${env.apiURL}/${fotoURL}`} style={{ width: '100%' }}></img>
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-white" onClick={() => setFotoOpen(!fotoOpen)}>
                Cerrar
              </button>
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default CuentaProfile;
