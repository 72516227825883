import React, { useMemo } from 'react';
import env from '../../environment';
import LinkDownload from '../link/link-download';
import Pagination from '../pagination/pagination';
import { PanelCollapse } from '../panel/panel-collapse';
import LabelCategory from '../label/label-category';
import SelectColumnFilter from '../selectColumnFilter/SelectColumnFilter';
const SicLinkTable = ({
  blocking,
  data,
  toggleIds = () => {},
  setter = () => {},
  title,
  linkable = false,
  keyForm = 'id',
  parcelaName = false,
}) => {
  const columnsData = useMemo(
    () => [
      {
        Header: '',
        accessor: 'x',
        minWidth: 50,
        Cell: ({ row }) =>
          linkable ? (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={e => toggleIds(row.original[keyForm], e.target, setter)}
              />
            </div>
          ) : null,
      },
      {
        Header: 'Cooperativa',
        accessor: 'coop_nombre',
        sortable: false,
        Filter: linkable ? SelectColumnFilter : null,
        filter: linkable ? 'equals' : null,
      },
      {
        Header: 'Formulario',
        accessor: 'form_type',
        sortable: false,
        Cell: ({ value }) => {
          if (value === 'sic_pp') {
            return <LabelCategory label={'Parcela Principal'} color={env.colors.primary_300} />;
          } else if (value === 'tx_po') {
            return <LabelCategory label={'Plan Orgánico'} color={env.colors.neutral_200} />;
          } else if (value === 'tx_croquis') {
            return <LabelCategory label={'Croquis'} color={env.colors.secondary_200} />;
          }
          return <LabelCategory label={'ERROR'} color={env.colors.error} />;
        },
      },
      {
        Header: 'Nombre Parcela',
        accessor: 'parcela_nombre_otro',
        sortable: false,
        show: parcelaName,
      },
      {
        Header: 'Nombre Productor',
        accessor: 'id_socio_otro',
        sortable: false,
        show: !parcelaName,
      },
      // {
      //   Header: 'Localidad',
      //   accessor: 'productor_localidad',
      //   sortable: false,
      //   show: !parcelaName,
      // },
      // {
      //   Header: 'Municipio',
      //   accessor: 'productor_municipio',
      //   sortable: false,
      //   show: !parcelaName,
      // },
      {
        Header: 'Nombre Inspector',
        accessor: 'nombre_inspector',
        sortable: false,
      },
      {
        Header: 'UUID odk',
        accessor: 'uuid_odk',
        sortable: false,
      },
      {
        Header: 'Fecha de Levantamiento',
        accessor: 'fecha',
        sortable: true,
      },
      {
        Header: 'Descargar PDF',
        accessor: 'pdf',
        sortable: true,
        Cell: ({ row }) =>
          row.original.form_type !== 'tx_croquis' ? (
            <LinkDownload
              url={`pdf/${row.original.form_type}/${row.original.uuid}`}
              label="Descargar"
              icon={env.images.icon.pdf}></LinkDownload>
          ) : null,
      },
      {
        Header: 'Descargar EXCEL',
        accessor: 'xls',
        sortable: true,
        Cell: ({ row }) =>
          row.original.form_type !== 'tx_croquis' ? (
            <LinkDownload
              url={`xls/${row.original.form_type}/${row.original.uuid}`}
              label="Descargar"
              icon={env.images.icon.xls}></LinkDownload>
          ) : null,
      },
    ],
    []
  );

  return (
    <PanelCollapse title={title}>
      <Pagination blocking={blocking} columns={columnsData} data={data} pagination={linkable}></Pagination>
    </PanelCollapse>
  );
};

export default SicLinkTable;
