import React, { useState, useEffect } from 'react';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';

const FormSelectApi = ({ onFinish, url, id, label, name, error, keyArray, onChange, register, labelArray }) => {
  const [state, setState] = useState({
    items: [],
    blocking: true,
  });

  const onFinishSubmit = () => {
    onFinish();
  };

  useEffect(() => {
    Api.getAll(url).then(response => {
      let _items = response.data;
      setState({ items: _items, blocking: false });
      onFinishSubmit();
    });
    //   .catch(e => {});
  }, []);

  return (
    <>
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <BlockUi tag="div" blocking={state.blocking}>
          <select
            {...register(id)}
            id={id}
            name={name}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            defaultValue={0}
            onChange={onChange}>
            <option value={0}>--- SELECCIONE ---</option>

            {state.items.map((item, index) => (
              <option key={index} value={item[keyArray]}>
                {item[labelArray]}
              </option>
            ))}
          </select>
          {error && <div className="invalid-feedback">{error.message}</div>}
        </BlockUi>
      </div>
    </>
  );
};
export default FormSelectApi;
