import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../api/global.service';
import 'bootstrap/dist/css/bootstrap.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';

const sharedNameForm = 'Usuario';
const sharedTable = 'users';
const sharedURL = '/admin/usuarios/list';

const UsuariosCRUD = () => {
  const { uuid } = useParams();
  const [name] = useState('Usuarios');
  const [nameForm] = useState(sharedNameForm);
  const [table] = useState(sharedTable);
  const [url] = useState(sharedURL);
  const [blocking, setBlocking] = useState(false);

  const [IdRol, setIdRol] = useState(0);
  const [IdCooperativa, setIdCooperativa] = useState(0);

  const schema = yup.object().shape({
    email: yup.string().required('Usuario es requerido'),
    username: yup.string().required('Teléfono es requerido'),
    nombres: yup.string().required('Nombres es requerido'),
    apellido_paterno: yup.string().required('Apellido paterno es requerido'),
    password: yup.string().required('Contraseña es requerido'),
    id_rol: yup.string().required('Rol es requerido'),
    id_cooperativa: yup.string().required('Cooperativa es requerido'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      email: '',
      username: '',
      password: '',
      nombres: '',
      apellido_paterno: '',
      apellido_materno: '',
      foto: '',
      id_rol: 1,
      id_cooperativa: 1,
    },
  });

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);
      Api.get('users', uuid)
        .then(response => {
          response.data[0].password = '';
          reset(response.data[0]);
          setIdRol(response.data[0].id_rol);
          setIdCooperativa(response.data[0].id_cooperativa);
          setBlocking(false);
        })
        .catch(e => {
          setBlocking(false);
        });
    }
  }, []);

  const onSubmit = values => {
    var item = {
      email: values.email,
      username: values.username,
      password: values.password,
      nombres: values.nombres,
      apellido_paterno: values.apellido_paterno,
      apellido_materno: values.apellido_materno,
      foto: values.foto,
      id_rol: IdRol,
      id_cooperativa: IdCooperativa,
    };
    setBlocking(true);
    if (uuid == null) {
      //CREATE
      Api.create(table, item)
        .then(response => {
          let data = response.data[0];
          setBlocking(false);
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se registró con éxito la ${nameForm.toLowerCase()} -> ${data.uuid}`,
          });
          window.location.href = url;
        })
        .catch(e => {
          setBlocking(false);
          Swal.fire({
            icon: 'error',
            title: '',
            text: e.response.data.message,
          });
        });
    } else {
      //EDIT
      Api.update(table, uuid, item)
        .then(response => {
          let data = response.data[0];
          setBlocking(false);
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se actualizó con éxito la ${nameForm.toLowerCase()} -> ${data.uuid}`,
          });
          window.location.href = url;
        })
        .catch(e => {
          setBlocking(false);
          Swal.fire({
            icon: 'error',
            title: '',
            text: e.response.data.message,
          });
        });
    }
  };

  return (
    <>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/usuarios/list">Usuarios</Link>
          </li>
          {/* <li className="breadcrumb-item"><Link to={url}>{name}</Link></li> */}
          <li className="breadcrumb-item active">{uuid == null ? 'Nuevo' : 'Editar'}</li>
        </ol>
        <h1 className="page-header">
          {name} / {uuid == null ? 'Nuevo' : 'Editar'}
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              {/* <PanelHeader>{nameForm}</PanelHeader> */}
              <PanelBody>
                <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12">
                      <FormInput
                        id="uuid"
                        type="hidden"
                        name="uuid"
                        label="UUID"
                        error={errors.uuid}
                        register={register}
                        required
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="nombres"
                          type="text"
                          name="nombres"
                          label="Nombre(s)"
                          error={errors.nombres}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="apellido_paterno"
                          type="text"
                          name="apellido_paterno"
                          label="Apellido Paterno"
                          error={errors.apellido_paterno}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="apellido_materno"
                          type="text"
                          name="apellido_materno"
                          label="Apellido Materno"
                          error={errors.apellido_materno}
                          register={register}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="email"
                          type="text"
                          name="email"
                          label="Usuario"
                          error={errors.email}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="username"
                          type="text"
                          name="username"
                          label="Teléfono"
                          error={errors.username}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="password"
                          type="text"
                          name="password"
                          label="Contraseña"
                          error={errors.password}
                          register={register}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormSelectApi
                          id="id_rol"
                          name="id_rol"
                          label="Rol"
                          url="roles"
                          keyArray="id"
                          labelArray="nombre"
                          showLoading={false}
                          onChange={e => {
                            setIdRol(e.target.value);
                          }}
                          error={errors.id_rol}
                          onFinish={() => {}}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormSelectApi
                          id="id_cooperativa"
                          name="id_cooperativa"
                          label="Cooperativa"
                          url="cooperativas"
                          keyArray="id"
                          labelArray="nombre"
                          showLoading={false}
                          onChange={e => {
                            setIdCooperativa(e.target.value);
                          }}
                          error={errors.id_cooperativa}
                          onFinish={() => {}}
                          register={register}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <FormInput
                          id="foto"
                          type="text"
                          name="foto"
                          label="Foto"
                          error={errors.foto}
                          register={register}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 offset-md-9">
                      <button className="btn btn-primary btn-block" type="submit">
                        Guardar
                      </button>
                    </div>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export function deleteByUUID(uuid) {
  //DELETE
  Swal.fire({
    title: '¿Esta seguro?',
    text: 'El registro se eliminará',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar!',
  }).then(result => {
    if (result.isConfirmed) {
      Api.delete(sharedTable, uuid)
        .then(response => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se eliminó con éxito la ${sharedNameForm.toLowerCase()} -> ${uuid}`,
          });
          window.location.href = sharedURL;
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: e,
          });
          // obtenerUsuarios();
        });
    }
    // obtenerUsuarios();
  });
}

export default UsuariosCRUD;
