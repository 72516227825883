import React, { useState, useEffect } from 'react';

const SelectColumn = ({ columns, setFilter }) => {
  const [value, setValue] = useState(-1);

  useEffect(() => {
    handlerChange(0, columns);
  }, []);

  const handlerChange = (index, columns) => {
    let obj = columns[index];
    setValue(index);
    setFilter({ index: index, target: obj });
  };

  return (
    <select
      className="mr-4"
      value={value}
      onChange={e => {
        handlerChange(parseInt(e.target.value), columns);
      }}
      style={{
        width: 'calc(100% - 54px)',
        borderRadius: '5px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        padding: '8px',
        borderColor: '#DADADA',
      }}>
      {columns.map((option, index) => (
        <option key={index} value={index}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectColumn;
