import React, { useState } from 'react';
import { Panel } from '../../components/panel/panel.jsx';
import Card from '../../components/card/card.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../api/global.service';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import {
  BarChart,
  PieChart,
  Pie,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Text,
  Label,
} from 'recharts';
import useLoadBotman from '../../hooks/useLoadBotman.jsx';

const COLORS_PIE = ['#A46B00', env.colors.secondary_300];
const COLORS_DON = [env.colors.neutral_3, env.colors.secondary_500];

const InicioResume = () => {
  const [idCiclo, setIdCiclo] = useState(0);
  const [blockingParcelasTipos, setBlockingParcelasTipos] = useState(true);
  const [blockingParcelasGeos, setBlockingParcelasGeos] = useState(true);
  const [blockingMultiBar, setBlockingMultiBar] = useState(true);
  const [totalesP, setTotalesP] = useState(0);
  const [totalesPP, setTotalesPP] = useState(0);
  const [totalesPA, setTotalesPA] = useState(0);
  const [totalesPD, setTotalesPD] = useState(0);
  const [totalesPPD, setTotalesPPD] = useState(0);
  const [dataGraficaParcelasTipos, setDataGraficaParcelasTipos] = useState([]);
  const [dataGraficaParcelasGeos, setDataGraficaParcelasGeos] = useState([]);
  const [dataGraficaMunicipios, setDataGraficaMunicipios] = useState([]);
  const [dataGraficaLocalidades, setDataGraficaLocalidades] = useState([]);
  useLoadBotman();

  const [isLocalidades, setIsLocalidades] = useState(false);

  const getAvanceMunicipios = value => {
    setBlockingMultiBar(true);
    Api.search('dashboard/avances/municipios', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        setBlockingMultiBar(false);
        let dataResponse = response.data;
        setDataGraficaMunicipios(dataResponse);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingMultiBar(false);
      });
  };

  const getAvanceLocalidades = id_municipio => {
    setBlockingMultiBar(true);
    Api.search('dashboard/avances/localidades', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: idCiclo },
        { column: 'id_municipio', operator: '=', value: id_municipio },
      ],
    })
      .then(response => {
        setBlockingMultiBar(false);
        let dataResponse = response.data;
        setDataGraficaLocalidades(dataResponse);
        setIsLocalidades(true);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingMultiBar(false);
      });
  };

  const setFilter = value => {
    setIdCiclo(value.target.id);
    getTotalesParcelasTipos(value);
    getTotalesParcelasGeos(value);
    getAvanceMunicipios(value);
  };

  const getTotalesParcelasTipos = value => {
    setBlockingParcelasTipos(true);
    setDataGraficaParcelasTipos([]);
    setTotalesP(0);
    setTotalesPP(0);
    setTotalesPA(0);

    Api.search('dashboard/totales/parcelas/tipos', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        setBlockingParcelasTipos(false);
        let dataResponse = response.data;
        if (typeof dataResponse[0].id_cooperativa !== 'undefined') {
          setDataGraficaParcelasTipos([
            { name: 'Otras parcelas', value: Number(dataResponse[0].sic_porcentaje_pa) },
            { name: 'Parcela principal', value: Number(dataResponse[0].sic_porcentaje_pp) },
          ]);
          setTotalesP(dataResponse[0].sic_total);
          setTotalesPP(dataResponse[0].sic_total_pp);
          setTotalesPA(dataResponse[0].sic_total_pa);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingParcelasTipos(false);
      });
  };

  const getTotalesParcelasGeos = value => {
    setBlockingParcelasGeos(true);
    setDataGraficaParcelasGeos([]);
    setTotalesPD(0);
    setTotalesPPD(0);
    Api.search('dashboard/totales/parcelas/geos', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        setBlockingParcelasGeos(false);
        let dataResponse = response.data;
        if (typeof dataResponse[0].id_cooperativa !== 'undefined') {
          setDataGraficaParcelasGeos([
            { name: 'Pendientes de geolocalizar', value: Number(dataResponse[0].geo_porcentaje_pendiente) },
            { name: 'Parcelas geolocalizadas', value: Number(dataResponse[0].geo_porcentaje_realizado) },
          ]);
          setTotalesPD(dataResponse[0].total_geo);
          setTotalesPPD(dataResponse[0].pendientes_geo);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingParcelasGeos(false);
      });
  };
  const valueAccessor = attribute => ({ payload }) => {
    let value = payload[attribute];
    if (value > 0) {
      if (!isLocalidades) {
        if (value > 10) {
          return value;
        } else {
          return '';
        }
      } else {
        return value;
      }
    } else {
      return '';
    }
  };

  const handleBarClick = e => {
    let currentPayload = e.activePayload[0].payload;
    getAvanceLocalidades(currentPayload.id_municipio);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomizedLabelB = ({ kapi, metric, viewBox }) => {
    return (
      <Text x={0} y={0} dx={-350} dy={40} textAnchor="start" width={400} transform="rotate(-90)">
        {'Nº de productores'}
      </Text>
    );
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item active">Inicio</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <div className="row">
        <div className="col-xl-6 pr-4">
          <BlockUi tag="div" message="" blocking={blockingParcelasTipos} renderChildren={true}>
            <div
              className="row card-graph"
              style={{ height: 400, backgroundColor: 'white', borderRadius: '20px 20px 20px 20px', padding: '20px' }}>
              <div className="col-xl-12 col-md-12">
                <div className="row card-dashboard" style={{ height: 125, marginLeft: -30 }}>
                  <div className="col-xl-3 col-md-3">
                    <img src={env.images.card.p} className="pt-2" />
                  </div>
                  <div className="col-xl-9 col-md-9">
                    <h1 className="font-lato-normal-600">
                      <span style={{ fontSize: 55, marginLeft: -50 }}>
                        {totalesP?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                      </span>{' '}
                      <span style={{ fontSize: 18, fontWeight: 'normal' }}>{'Parcelas registradas'}</span>
                    </h1>
                  </div>
                </div>

                <div className="row card-graph">
                  <div className="col-xl-6 col-md-6">
                    <div className="row card-graph">
                      <div className="col-xl-12 col-md-12">
                        <Card
                          height={100}
                          label="Parcelas principales"
                          value={totalesPP?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                          image={env.images.card.circulo_E49D2A}></Card>
                      </div>
                    </div>
                    <div className="row card-graph">
                      <div className="col-xl-12 col-md-12">
                        <Card
                          height={100}
                          label="Otras parcelas"
                          value={totalesPA?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                          image={env.images.card.circulo_A46B00}></Card>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-6">
                    <ResponsiveContainer width="100%" height={'100%'}>
                      <PieChart width={200} height={200}>
                        <Pie
                          data={dataGraficaParcelasTipos}
                          cx={100}
                          cy={125}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={90}
                          fill="#8884d8"
                          dataKey="value"
                          isAnimationActive={false}>
                          {dataGraficaParcelasTipos.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS_PIE[index % COLORS_PIE.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </BlockUi>
        </div>

        <div className="col-xl-6 pr-4">
          <div
            className="row card-graph"
            style={{ height: 400, backgroundColor: 'white', borderRadius: '20px 20px 20px 20px', padding: '20px' }}>
            <BlockUi tag="div" message="" blocking={blockingParcelasGeos} renderChildren={true}>
              <div className="col-xl-12 col-md-12">
                <div className="col-xl-12 col-md-12">
                  <div className="row card-dashboard" style={{ height: 125, marginLeft: -50 }}>
                    <div className="col-xl-3 col-md-3">
                      <img src={env.images.card.pd} className="pt-2" />
                    </div>
                    <div className="col-xl-9 col-md-9">
                      <h1 className="font-lato-normal-600">
                        <span style={{ fontSize: 55, marginLeft: -25 }}>
                          {totalesPD?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </span>{' '}
                        <span style={{ fontSize: 18, fontWeight: 'normal' }}>{'Parcelas geolocalizadas'}</span>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="row card-graph">
                  <div className="col-xl-6 col-md-6">
                    <div className="row card-graph">
                      <div className="col-xl-12 col-md-12">
                        <Card
                          height={100}
                          label="Parcelas geolocalizadas"
                          value={totalesPD?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                          image={env.images.card.circulo_BC8268}></Card>
                      </div>
                    </div>
                    <div className="row card-graph">
                      <div className="col-xl-12 col-md-12">
                        <Card
                          height={100}
                          label="Pendientes de geolocalizar"
                          value={totalesPPD?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                          image={env.images.card.circulo_FFEED9}></Card>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-6">
                    <ResponsiveContainer width="100%" height={'100%'}>
                      <PieChart width={200} height={200}>
                        <Pie
                          data={dataGraficaParcelasGeos}
                          cx={100}
                          cy={125}
                          innerRadius={60}
                          outerRadius={90}
                          fill="#8884d8"
                          paddingAngle={1}
                          dataKey="value">
                          {dataGraficaParcelasGeos.map((entry, index) => (
                            <>
                              <Cell key={`cell-${index}`} fill={COLORS_DON[index % COLORS_DON.length]} />
                              <Label
                                style={{ fontSize: 18 }}
                                value={parseInt(dataGraficaParcelasGeos[1]?.value) + '%'}
                                position="center"
                              />
                            </>
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>

      <br />
      <div className="row card-table">
        <div className="col-xl-12 pb-4">
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <h6 className="font-lato-normal-400">AVANCES DE LEVANTAMIENTO POR MUNICIPIO Y COMUNIDAD</h6>
                {!isLocalidades && (
                  <a
                    className="font-lato-normal-400"
                    onClick={() => setIsLocalidades(false)}
                    style={{ color: '#5B5B5B', fontSize: 14, textDecoration: 'none' }}>
                    {'Selecciona un municipio para ver el avance de sus comunidades'}
                  </a>
                )}

                {isLocalidades && (
                  <a
                    onClick={() => setIsLocalidades(false)}
                    style={{ marginTop: -40, color: '#4C6700', fontSize: 14, textDecoration: 'none' }}
                    className="float-right">
                    {'<- Todos los municipios'}
                  </a>
                )}
                <BlockUi tag="div" message="" blocking={blockingMultiBar} renderChildren={true}>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={isLocalidades ? dataGraficaLocalidades : dataGraficaMunicipios}
                      onClick={handleBarClick}>
                      <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
                      <XAxis angle={0} dataKey={isLocalidades ? 'localidad' : 'municipio'} />
                      <YAxis tick={false} label={<CustomizedLabelB />} />
                      <Tooltip />
                      <Legend />

                      <Bar
                        legendType={'none'}
                        name="Realizado"
                        radius={5}
                        barSize={150}
                        dataKey="total_levantado"
                        stackId="a"
                        fill={isLocalidades ? '#4A6700' : '#C7AE34'}>
                        <LabelList
                          position={'insideBottom'}
                          style={{ textAnchor: 'middle', fontSize: '14', fill: 'white' }}
                          valueAccessor={valueAccessor('total_levantado')}
                        />
                      </Bar>

                      <Bar
                        legendType={'none'}
                        name="Pendiente"
                        radius={5}
                        barSize={150}
                        dataKey="total_pendiente"
                        stackId="a"
                        fill={isLocalidades ? '#dbe1cc' : '#f4efd6'}>
                        <LabelList
                          position={'insideTop'}
                          style={{ textAnchor: 'middle', fontSize: '14', fill: 'black' }}
                          valueAccessor={valueAccessor('total_pendiente')}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </BlockUi>
              </Panel>
            </div>
          </div>
        </div>
      </div>
      {/* show bot status if needed <p>{loadingBot}</p> */}
    </div>
  );
};

export default InicioResume;
