import React, { useEffect, useMemo, useState, createRef } from 'react';
import Pagination from '../pagination/pagination';
import Api from '../../api/global.service';
import EditLink from '../link/edit-link';
import ModalComite from '../modal/modal-comite';

const ComiteCondicionesTable = ({ blocking, id_cuenta }) => {
  const [edit, setEdit] = useState(null);
  const dialog = createRef();
  const onClickEdit = (event, data) => {
    event.preventDefault();
    setEdit(data);
  };

  useEffect(() => {
    if (edit !== null) {
      dialog.current.editText(edit);
    }
  }, [edit]);

  const columnsData = useMemo(
    () => [
      {
        Header: 'Ciclo',
        accessor: 'ciclo',
        sortable: true,
        width: 80,
      },
      {
        Header: 'Resolución Comité',
        accessor: 'condicion_str',
        sortable: true,
      },
      {
        Header: 'Observación, Condición o Sanción',
        accessor: 'observ_condicion',
        sortable: true,
        minWidth: 220,
      },
      {
        Header: 'Plazo Cumplimiento',
        accessor: 'plazo',
        sortable: true,
        width: 90,
      },
      {
        Header: 'Cumplido',
        accessor: 'verif_cumplido_str',
        sortable: true,
        width: 90,
        Cell: ({ row, value }) => {
          // eslint-disable-next-line eqeqeq
          if (row.original.tipo != 2) {
            return <p style={{ margin: 0 }}>N/A</p>;
          }
          const editData = {
            ...row.original,
            accessor: 'editar_comite_detalle',
            id_cuenta: id_cuenta,
          };
          return (
            <EditLink onClickEdit={e => onClickEdit(e, editData)}>
              <p style={{ margin: 0 }}>{value}</p>
            </EditLink>
          );
        },
      },
      {
        Header: 'Fecha',
        accessor: 'verif_fecha',
        sortable: true,
        width: 90,
        Cell: ({ row, value }) => {
          // eslint-disable-next-line eqeqeq
          if (row.original.tipo != 2) {
            return <p style={{ margin: 0 }}>N/A</p>;
          }
          const editData = {
            ...row.original,
            accessor: 'editar_comite_detalle',
            id_cuenta: id_cuenta,
          };
          return (
            <EditLink onClickEdit={e => onClickEdit(e, editData)}>
              <p style={{ margin: 0 }}>{value}</p>
            </EditLink>
          );
        },
      },
    ],
    [id_cuenta]
  );

  const [data, setData] = useState([]);
  useEffect(() => {
    if (id_cuenta) {
      Api.get('comite_evaluaciones_cuenta_details', id_cuenta).then(response => {
        setData(response.data);
      });
    }
  }, [id_cuenta]);

  return (
    <>
      <ModalComite ref={dialog} />
      <Pagination blocking={blocking} columns={columnsData} data={data} pagination={false} />
    </>
  );
};

export default ComiteCondicionesTable;
