import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../pagination/pagination';
import { PanelCollapse } from '../panel/panel-collapse';
import SelectColumnFilter from '../selectColumnFilter/SelectColumnFilter';

const SicLinkCuentasTable = ({ blocking, data, toggleIds = () => {}, setter = () => {}, title, linkable = false }) => {
  const columnsData = useMemo(
    () => [
      {
        Header: '',
        accessor: 'x',
        minWidth: 50,
        Cell: ({ row }) =>
          linkable ? (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={e => toggleIds(row.original.id_cuenta, e.target, setter)}
              />
            </div>
          ) : null,
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
        sortable: true,
        Cell: ({ value, row }) => (
          <Link
            to={{ pathname: `/admin/cuentas/perfil/${row.original.uuid}` }}
            className="m-r-2"
            style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'Cooperativa',
        accessor: 'nombre_coop',
        sortable: true,
        Filter: linkable ? SelectColumnFilter : null,
        filter: linkable ? 'equals' : null,
      },

      {
        Header: 'REGIÓN',
        accessor: 'cuenta_region',
        sortable: true,
        Filter: linkable ? SelectColumnFilter : null,
        filter: linkable ? 'equals' : null,
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
        Filter: linkable ? SelectColumnFilter : null,
        filter: linkable ? 'equals' : null,
      },
      {
        Header: 'LOCALIDAD',
        accessor: 'localidad',
        sortable: true,
        Filter: linkable ? SelectColumnFilter : null,
        filter: linkable ? 'equals' : null,
      },
      // {
      //   Header: 'CATEGORÍA',
      //   accessor: 'cuenta_categoria',
      //   sortable: true,
      //   Cell: ({ value, row }) => (
      //     <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>
      //   ),
      //   minWidth: 90,
      // },
      {
        Header: 'FECHA DE INGRESO',
        accessor: 'fecha_ingreso',
        sortable: true,
      },
    ],
    ['linkable', 'setter', 'toggleIds']
  );

  return (
    <PanelCollapse title={title}>
      <Pagination blocking={blocking} columns={columnsData} data={data} pagination={linkable}></Pagination>
    </PanelCollapse>
  );
};

export default SicLinkCuentasTable;
