import React from 'react';
import LabelCategory from '../label/label-category.jsx';

class CardCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row card-category-dashboard" style={{ height: this.props.height }}>
        <div className="col-xl-6 col-md-6">
          <img src={this.props.image} className="pt-2" />
          <LabelCategory label={this.props.category} color={this.props.color}></LabelCategory>
        </div>
        <div className="col-xl-6 col-md-6">
          <h1 className="font-lato-normal-600">{this.props.value}</h1>
          <h6 className="font-lato-normal-400">{this.props.label}</h6>
        </div>
      </div>
    );
  }
}

export default CardCategory;
