import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import ModalEntrega from '../../components/modal/modal-entrega.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import Pagination from '../../components/pagination/pagination';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

import { subscribe, unsubscribe } from '../../components/events';
import LinkDownload from '../../components/link/link-download.jsx';
import toggleIds from '../../utils/toggleIds.js';

const EntregasXProductorList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [ids, setIds] = useState([]);
  const [folios, setFolios] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const dialog = createRef();
  const [menuVisible, setMenuVisible] = useState(false);
  const [date, setDate] = useState(null);
  const [report, setReport] = useState('all');
  const [blockingReport, setBlockingReport] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ENTREGA',
        columns: [
          {
            Header: '',
            accessor: 'x',
            minWidth: 50,
            Cell: ({ row }) => {
              if (row.original.folio_entrega == '-') {
                return (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onClick={e => toggleIds(row.original.id_acopio, e.target)}
                    />
                  </div>
                );
              } else {
                return '';
              }
            },
          },
          {
            Header: 'FECHA ACOPIO',
            accessor: 'fecha_acopio',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return <p className="text-left">{value != null ? value.substr(0, 10) : ''}</p>;
            },
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.estatus_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
          {
            Header: 'FOLIO ENTREGA',
            accessor: 'folio_entrega',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'FECHA ENTREGA',
            accessor: 'fecha_entrega',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return <p className="text-left">{value != null ? value.substr(0, 10) : ''}</p>;
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'ID YA',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CLAVE TX',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'FOLIO RECIBIDO',
            accessor: 'folio_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE ENTREGA',
        columns: [
          {
            Header: 'Nº RECIPIENTES',
            accessor: 'no_bultos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'PESO BRUTO (KGS)',
            accessor: 'peso_bruto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'PESO TARA (KGS)',
            accessor: 'peso_tara',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'PESO NETO (KGS)',
            accessor: 'peso_neto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'PRECIO A PRODUCTOR ($/KG)',
            accessor: 'precio_por_kg',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'IMPORTE TOTAL ($)',
            accessor: 'importe_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'DESCUENTOS DEUDAS',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'PAGADO A PRODUCTOR ($)',
            accessor: 'pagado_a_productor',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO DE LA ENTREGA ($)',
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            Cell: ({ value, row }) => {
              let v = value;
              if (v != null && v != '')
                return (
                  <LinkDownload
                    isZip
                    url={`recibos/acopios/${row.original.acopio_uuid}`}
                    label="Descargar"
                    icon={env.images.icon.pdf}></LinkDownload>
                );
              else return '';
            },
          },
        ],
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'fecha_acopio', label: 'FECHA ACOPIO' },
      { value: 2, target: 'estatus', label: 'ESTATUS' },
      { value: 3, target: 'folio_entrega', label: 'FOLIO ENTREGA' },
      { value: 4, target: 'fecha_entrega', label: 'FECHA ENTREGA' },

      { value: 5, target: 'productor_clave_tx', label: 'ID YA' },
      { value: 6, target: 'productor_codigo_tx', label: 'CLAVE TX' },
      { value: 7, target: 'folio_acopio', label: 'FOLIO RECIBIDO' },
      { value: 8, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 9, target: 'localidad', label: 'LOCALIDAD' },
      { value: 10, target: 'region', label: 'REGIÓN' },
      { value: 11, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 12, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      { value: 13, target: 'no_bultos', label: 'Nº RECIPIENTES' },
      { value: 14, target: 'peso_bruto', label: 'PESO BRUTO (KGS)' },
      { value: 15, target: 'peso_tara', label: 'PESO TARA (KGS)' },
      { value: 16, target: 'peso_neto', label: 'PESO NETO (KGS)' },
      { value: 17, target: 'precio_por_kg', label: 'PRECIO A PRODUCTOR (PERG)' },
      { value: 18, target: 'importe_total', label: 'IMPORTE TOTAL ($)' },
      { value: 19, target: 'total_anticipos_recuperados', label: 'DESCUENTOS DEUDAS' },
      { value: 20, target: 'pagado_a_productor', label: 'PAGADO A PRODUCTOR ($)' },
      { value: 21, target: 'total_saldo', label: 'SALDO DE LA ENTREGA ($)' },
    ],
    []
  );

  useEffect(() => {
    if (ciclo.id) {
      dialog.current.setIdCiclo(ciclo.id);
    }
  }, [ciclo]);

  useEffect(() => {
    dialog.current.setIdsAcopios(ids.join(','));
  }, [ids]);

  useEffect(() => {
    dialog.current.setFolios(folios);
  }, [folios]);

  useEffect(() => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    setDate(curr.toISOString().substring(0, 10));

    subscribe('Entrega::onSave', e => handleSave(e));

    return () => {
      unsubscribe('Entrega::onSave');
    };
  }, []);

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const updateData = id_ciclo => {
    setBlocking(true);
    Api.Acopio.search('entregas_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);

        var folios = response.data
          .filter(function(item) {
            return item.folio_entrega != null && item.folio_entrega != '-' && item.folio_entrega != '';
          })
          .map(function(item) {
            return { value: item.folio_entrega, label: item.folio_entrega, date: item.fecha_entrega + ' 12:00:00' };
          })
          .sort(function(a, b) {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            return 0;
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                t => t.value === value.value // && t.name === value.name
              )
          )
          .sort(function(a, b) {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });

        setFolios(folios);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgba(240, 224, 207, 0.5)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    setIds([]);

    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se asignó con éxito el folio ${event.detail.folio} a los acopios seleccionados.`,
    });

    updateData(event.detail.id_ciclo);
  };

  const handleDownloadReport = event => {
    event.preventDefault();
    let reportUrl = `xls/entregas_acopios/${ciclo?.uuid}`;
    if (report !== 'all') {
      // reportUrl = `xls/entregas_acopios/${ciclo?.uuid}/2022-12-14`;
      reportUrl = `xls/entregas_acopios/${ciclo?.uuid}/${date}`;
    }

    setBlockingReport(true);
    Api.download(reportUrl)
      .then(response => {
        let fileName = response.headers['content-disposition'].split('filename=')[1];

        const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!

        setBlockingReport(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlockingReport(false);
      });
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Entregas</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <Link
                  to="#"
                  onClick={() => setMenuVisible(!menuVisible)}
                  className="m-r-2"
                  style={{ color: '#789D16' }}>
                  <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
                </Link>
                {menuVisible === true && (
                  <form
                    className="dropdown-menu dropdown-menu dropdown-menu-right"
                    style={{ display: 'block', padding: '16px', top: 32 }}>
                    <div className="form-check pb-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="report"
                        value="all"
                        checked={report === 'all'}
                        onClick={() => setReport('all')}
                      />
                      <label className="form-check-label" htmlFor="dropdownCheck">
                        Todas las entregas
                      </label>
                    </div>
                    <div className="form-check pb-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="report"
                        value="date"
                        checked={report !== 'all'}
                        onClick={() => setReport('date')}
                      />
                      <label className="form-check-label" htmlFor="dropdownCheck">
                        Entregas por fecha
                      </label>
                    </div>
                    {report === 'date' && (
                      <div className="form-group">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="DD/MM/YYYY"
                          defaultValue={date}
                          onChange={e => setDate(e.target.value)}
                        />
                      </div>
                    )}
                    {blockingReport && (
                      <div className="d-flex justify-content-start float-right">
                        <div
                          className="spinner-border text-warning mr-2"
                          role="status"
                          aria-hidden="true"
                          style={{ height: 24, width: 24 }}></div>
                        <span className="mt-1">Descargando ...</span>
                      </div>
                    )}
                    {!blockingReport && (
                      <button
                        type="submit"
                        className="btn btn-xs btn-outline-secondary btn-sirio float-right"
                        onClick={e => handleDownloadReport(e)}>
                        Descargar
                      </button>
                    )}
                  </form>
                )}
              </div>
            )}
          </div>
        </div>

        <Pagination
          blocking={blocking}
          selected="id"
          columns={columns}
          data={data}
          pagination={true}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></Pagination>

        <div className="row">
          <div className="col-3">
            {auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_NUEVO') && (
              <button
                onClick={() => dialog.current.show()}
                type="button"
                className="btn btn-outline-secondary"
                style={{ borderRadius: '20px 20px 20px 20px' }}
                disabled={ids.length == 0}>
                <i className="fa fa-plus"></i> Actualizar estado
              </button>
            )}
          </div>
        </div>

        <ModalEntrega ref={dialog} title="Actualizar estado"></ModalEntrega>
      </CardContainer>
    </div>
  );
};

export default EntregasXProductorList;
