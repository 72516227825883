import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../components/content/auth.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { deleteByUUID } from './cooperativas-crud.js';
import env from '../../environment.js';

import Pagination from '../../components/pagination/pagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

const CooperativasList = () => {
  const auth = useAuth();

  const [name] = useState('Cooperativas');
  const [table] = useState('cooperativas');
  const [url] = useState('/admin/cooperativas');

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ACCIONES',
        accessor: 'uuid',
        Cell: cell => (
          <div className="with-btn text-nowrap">
            {auth.hasPermission('COOPERATIVAS_EDITAR') && (
              // eslint-disable-next-line react/destructuring-assignment
              <Link to={{ pathname: `${url}/edit/${cell.row.original.uuid}` }} className="m-r-2">
                <button type="button" className="btn btn-sm btn-primary m-r-2">
                  <i className="fa fa-pen"></i> Editar
                </button>
              </Link>
            )}
            {auth.hasPermission('COOPERATIVAS_ELIMINAR') && (
              <button type="button" onClick={() => handleDelete(cell)} className="btn btn-sm btn-danger m-r-2">
                <i className="fa fa-trash"></i> Eliminar
              </button>
            )}
          </div>
        ),
      },
      {
        Header: 'NOMBRE',
        accessor: 'nombre',
        sortable: true,
        Cell: ({ row, value }) => (
          <div style={{ width: '200px' }}>
            <img
              src={`${env.assetURL}/${row.original.logo}`}
              alt=""
              style={{ width: '30px', height: '30px', marginRight: '6px', borderRadius: '30px' }}
            />
            <span>{value}</span>
          </div>
        ),
      },
      {
        Header: 'DESCRIPCIÓN',
        accessor: 'descripcion',
        sortable: true,
      },
      {
        Header: 'LOGO',
        accessor: 'logo',
        sortable: true,
      },
      {
        Header: 'CUENTA KOBO',
        accessor: 'cuenta_kobo',
        sortable: true,
      },
      {
        Header: 'CERTIFICACIÓN',
        accessor: 'certificacion',
        sortable: true,
      },
      {
        Header: 'TOTAL DE CUENTAS',
        accessor: 'total_cuentas',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
      {
        Header: 'FECHA DE CREACIÓN',
        accessor: 'created_at',
        sortable: true,
      },
      {
        Header: 'FECHA DE ACTUALIZACIÓN',
        accessor: 'updated_at',
        sortable: true,
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'NOMBRE' },
      { value: 2, target: 'descripcion', label: 'DESCRIPCIÓN' },
      { value: 3, target: 'logo', label: 'LOGO' },
      { value: 4, target: 'cuenta_kobo', label: 'CUENTA KOBO' },
      { value: 5, target: 'certificacion', label: 'CERTIFICACIÓN' },
      { value: 6, target: 'total_cuentas', label: 'TOTAL DE CUENTAS' },
      { value: 7, target: 'created_at', label: 'FECHA DE CREACIÓN' },
      { value: 8, target: 'updated_at', label: 'FECHA DE ACTUALIZACIÓN' },
    ],
    []
  );

  const handleDelete = event => {
    deleteByUUID(event.cell.row.original.uuid);
  };

  useEffect(() => {
    setBlocking(true);
    Api.search(table, {
      sort: [{ column: 'nombre', sort: 'asc' }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  }, []);

  const setDataFilter = data => {
    setData(data);
  };

  return (
    <div>
      {auth.hasAccess('COOPERATIVAS') && (
        <>
          <Breadcrumb>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item active">{name}</li>
            </ol>
          </Breadcrumb>

          <CardContainer height={'100%'}>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataFilter(evt);
                  }}></SearchColumn>
              </div>
              <div className="col-2 text-right">
                {auth.hasPermission('COOPERATIVAS_NUEVO') && (
                  <Link to={{ pathname: `${url}/create` }} className="m-r-2">
                    <button type="button" className="btn btn-sm btn-gray m-r-2">
                      <i className="fa fa-plus"></i> Nuevo
                    </button>
                  </Link>
                )}
              </div>
            </div>

            {auth.hasPermission('COOPERATIVAS_VER') && (
              <Pagination blocking={blocking} columns={columns} data={data} pagination={true}></Pagination>
            )}
          </CardContainer>
        </>
      )}
    </div>
  );
};

export default CooperativasList;
