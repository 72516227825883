const Menu = [
  { path: '/dashboard', icon: 'fa fa-home', title: 'Inicio', permission: 'PADRON' },
  {
    // path: '/admin/cuentas',
    path: '/admin/cuentas/list',
    icon: 'fa fa-user-circle',
    title: 'Productores',
    permission: 'CUENTAS',
    children: [{ path: '/admin/cuentas/list', title: 'Listado productores', permission: 'CUENTAS' }],
  },
  // {
  //   path: '/admin/parcelas',
  //   icon: 'fa fa-map-marker',
  //   title: 'Parcelas',
  //   permission: 'PARCELAS',
  //   children: [{ path: '/admin/parcelas/list', title: 'Listado parcelas', permission: 'PARCELAS' }],
  // },
  {
    path: '/admin/padron/completo/list',
    icon: 'fa fa-file',
    title: 'Padrón',
    permission: 'PADRON',
    children: [
      { path: '/admin/padron/completo/list', title: 'Completo', permission: 'PADRON_COMPLETO' },
      { path: '/admin/padron/concentrado/list', title: 'Concentrado', permission: 'PADRON_CONCENTRADO' },
      { path: '/admin/padron/altas/list', title: 'Altas', permission: 'PADRON_ALTAS' },
      { path: '/admin/padron/bajas/list', title: 'Bajas', permission: 'PADRON_BAJAS' },
      { path: '/admin/padron/mapas/list', title: 'Mapas', permission: 'PADRON_MAPAS' },
    ],
  },
  {
    path: '/admin/acopio',
    icon: 'fa fa-store',
    title: 'Acopio',
    permission: 'ACOPIOS',
    children: [
      { path: '/admin/acopio/acopio_por_productor/list', title: 'Por productor', permission: 'ACOPIOS_X_PRODUCTOR' },
      {
        path: '/admin/acopio/entregas_por_productor/list',
        title: 'Entregas',
        permission: 'ACOPIOS_ENTREGAS_X_PRODUCTOR',
      },
      {
        path: '/admin/acopio/anticipos_por_productor/list',
        title: 'Anticipos',
        permission: 'ACOPIOS_ANTICIPOS_X_PRODUCTOR',
      },
      { path: '/admin/acopio/entregas_efectivo/list', title: 'Efectivo', permission: 'ACOPIOS_ENTREGAS_EFECTIVO' },
      {
        path: '/admin/acopio/precio_por_cooperativa/list',
        title: 'Precio',
        permission: 'ACOPIOS_PRECIO_X_COOPERATIVA',
      },
    ],
  },
  { path: '/admin/cooperativas/list', icon: 'fa fa-building', title: 'Cooperativas', permission: 'COOPERATIVAS' },
  { path: '/admin/usuarios/list', icon: 'fa fa-users', title: 'Usuarios', permission: 'USUARIOS' },
  // TODO: add permission! VINCULAR_SIC
  {
    path: '/admin/vincular-sic/list',
    icon: 'fa fa-link',
    title: 'Vincular SIC',
    permission: 'LOCALIDADES',
    children: [
      {
        path: '/admin/vincular-sic/list',
        icon: 'fa fa-user-circle',
        title: 'Productores',
        permission: 'LOCALIDADES',
      },
      {
        path: '/admin/vincular-sic-parcelas/list',
        icon: 'fa fa-map-marker',
        title: 'Parcelas',
        permission: 'LOCALIDADES',
      },
    ],
  },

  { path: '/admin/csv/list', icon: 'fa fa-table', title: 'CSV', permission: 'CSV' },
  { path: '/admin/localidades/list', icon: 'fa fa-map-marker', title: 'Localidades', permission: 'LOCALIDADES' },
  { path: '/admin/versiones/list', icon: 'fa fa-cogs', title: 'Versiones', permission: 'VERSIONES' },
];

export default Menu;
