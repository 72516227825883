import React, { useState,useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelCategory from '../../components/label/label-category.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';

import Pagination from '../../components/pagination/pagination';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import Swal from 'sweetalert2';
import ModalPadronAltas from '../../components/modal/modal-padron-altas-bajas.jsx';

const PadronBajasList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const [btnPendientes, setBtnPendientes] = useState(true);

  const report = 'padron_bajas';
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);
  const [prodSeleccionados, setProdSeleccionados] = useState([]);
  const [confirmBajas, setConfirmBajas] = useState(false);
  const dialog = React.createRef();

  const handleCheck = (event) => {
    let prodSel = idsSeleccionados;
    if (event.target.checked === true) {
      setIdsSeleccionados([...prodSel,event.target.value]);
    } else {
      prodSel = prodSel.filter(item => item !== event.target.value);
      setIdsSeleccionados(prodSel);
    }
  };

  const columns = [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: '-',
            accessor: 'productor_clave_tx',
            sortable: false,
            minWidth: 50,
            Cell: cell => {
              return (
                <>
                  <div className="form-check">
                    {cell.row.original.confirmado === '0' && (
                      <input 
                        className="form-check-input" 
                        name="productores"
                        type="checkbox" 
                        style={{ width: 20, height: 20,accentColor : '#374E00' }}
                        value={cell.row.original.uuid_cuentas_bajas_solicitud}
                        onChange={handleCheck}
                        checked={idsSeleccionados.includes(cell.row.original.uuid_cuentas_bajas_solicitud)}
                      />
                    )}
                    <label className="form-check-label" htmlFor="flexCheckChecked" style={{ marginTop: 5, paddingLeft: 5 }}>
                      {cell.value}
                    </label>
                  </div>


                </>
              );
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'MUNICIPIO',
            accessor: 'municipio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: cell => (
              <Link
                to={{ pathname: `/admin/cuentas/perfil/${cell.row.original.productor_uuid}` }}
                className="m-r-2"
                style={{ color: '#789D16' }}>
                <span>{cell.value}</span>
              </Link>
            ),
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: cell => (
              <LabelCategory label={cell.value} color={cell.row.original.cuenta_categoria_color}></LabelCategory>
            ),
          },
          {
            Header: 'MOTIVO DE LA BAJA',
            accessor: 'motivo_baja',
            sortable: true,
            minWidth: 90,
            // Cell: cell => (
            // 	<LabelCategory label={cell.value} color={cell.row.original.cuenta_categoria_color}></LabelCategory>
            // 	// <span className="badge rounded-4" style={{backgroundColor: cell.row.original.cuenta_categoria_color, fontSize: '0.75rem'}}>{cell.value}</span>
            // ),
          },
        ],
      },
      {
        Header: 'INFORMACIÓN PROGRAMA ORGÁNICO',
        columns: [
          {
            Header: 'FECHA INGRESO ORGÁNICO',
            accessor: 'productor_fecha_ingreso',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'FECHA INGRESO TRANSICIÓN',
            accessor: 'productor_fecha_transicion',
            sortable: true,
            minWidth: 50,
          },
          // {
          // 	Header: 'FECHA APLICACIÓN PROD. PROHIBIDOS',
          // 	accessor: 's3_cafeorganico_otrans_fechaprohibidos',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: 'PROD. PROHIBIDOS UTILIZADOS',
          // 	accessor: 's3_cafeorganico_otrans_insumosprohibidos',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: 'HAS TOTALES DE TERRENO',
          // 	accessor: 'parcela_superficie',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: 'HA CULTIVO CAFÉ',
          // 	accessor: 's3_cafeorganico_otrans_superficie',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: 'HA. CULTIVO ORGÁNICO',
          // 	accessor: 'has_cultivo_organico',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: '# PLANTAS TOTALES CAFÉ',
          // 	accessor: 's3_cafeorganico_otrans_numplantas',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: 'FECHA INSPECCIÓN INTERNA',
          // 	accessor: 's3_inspeccion_fecha',
          // 	sortable: true,
          // 	minWidth: 50
          // },
          // {
          // 	Header: 'NOMBRE INSPECTOR INTERN0',
          // 	accessor: 's1_lista_inspector_str',
          // 	sortable: true,
          // 	minWidth: 50
          // }
        ],
      },
      // {
      // 	Header: "SUPERFICIE CULTIVOS NO CAFÉ",
      //   columns: [
      // 		{
      // 			Header: 'AUTOCONSUMO',
      // 			accessor: 's2_autoconsumo_superficie',
      // 			sortable: true,
      // 			minWidth: 50
      // 		},
      // 		{
      // 			Header: 'OTROS CULTIVOS',
      // 			accessor: 's2_otrocultivo_superficie',
      // 			sortable: true,
      // 			minWidth: 50
      // 		},
      // 		{
      // 			Header: 'POTRERO',
      // 			accessor: 's2_potrero_superficie',
      // 			sortable: true,
      // 			minWidth: 50
      // 		},
      // 		{
      // 			Header: 'BOSQUE',
      // 			accessor: 's2_bosque_superficie',
      // 			sortable: true,
      // 			minWidth: 50
      // 		},
      // 		{
      // 			Header: 'ACAHUAL',
      // 			accessor: 's2_acahual_superficie',
      // 			sortable: true,
      // 			minWidth: 50
      // 		}
      // 	]
      // },
      // {
      // 	Header: "PARCELA 1 CULTIVO ORGÁNICO",
      //   columns: [
      // 		{
      // 			Header: 'NOMBRE DE PARCELA',
      // 			accessor: 'parcela_nombre',
      // 			sortable: true,
      // 			minWidth: 50,
      // 			Cell: cell => (
      // 				// <div className="with-btn text-nowrap">
      // 					<Link to={{pathname: `/admin/parcelas/perfil/${cell.row.original.parcela_uuid}`}} className="m-r-2" style={{color: '#789D16'}}>
      // 						<span>{cell.value}</span>
      // 					</Link>
      // 				// </div>
      // 			)
      // 		},
      // 		{
      // 			Header: '#DE PLANTAS',
      // 			accessor: 'parcela_plantas',
      // 			sortable: true,
      // 			minWidth: 50
      // 		},
      // 		{
      // 			Header: 'CULTIVO ANTERIOR',
      // 			accessor: 'parcela_cultivo_anterior',
      // 			sortable: true,
      // 			minWidth: 50
      // 		},
      // 		{
      // 			Header: 'CULTIVO A CERTIFICAR',
      // 			accessor: 'parcela_cultivo_certificar',
      // 			sortable: true,
      // 			minWidth: 50
      // 		}
      // 	]
      // }
    ];

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'productor_clave_tx', label: 'NO' },
      { value: 2, target: 'localidad', label: 'LOCALIDAD' },
      { value: 3, target: 'region', label: 'REGIÓN' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 7, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 8, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      {value: 9, target: 'motivo_baja', label: 'MOTIVO DE LA BAJA'},
      { value: 9, target: 'productor_fecha_ingreso', label: 'FECHA INGRESO ORGÁNICO' },
      { value: 10, target: 'productor_fecha_transicion', label: 'FECHA INGRESO TRANSICIÓN' },
    ],
    []
  );

  const getData = () => {
    setBlocking(true);
    Api.search('admin/sic/padron_bajas', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: ciclo.id },
        { column: 'confirmado', operator: '=', value: (btnPendientes) ? 0 : 1 }
    ],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  }

  const setFilter = value => {
    setCiclo(value.target);
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgba(240, 224, 207, 0.5)';
    } else {
      switch (j) {
        // case 0: return env.colors.neutral_600; break;
        case 1:
          return '#91918A';
        // case 2: return '#C7AE34';
        case 3:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const getPendientes = () => {
    setBtnPendientes(true);
  }

  const getHistorial = () => {
    setBtnPendientes(false);
  }

  useEffect(() => {
    if(ciclo?.id){
      getData();
    }
  }, [btnPendientes,ciclo])

  useEffect(() => {
    console.log(idsSeleccionados);
  }, [idsSeleccionados])

  

  const enviarBaja = (padronesSeleccionados) => {
    if(idsSeleccionados.length > 0 ){
      setBlocking(true);
      let data = { uuids: idsSeleccionados, id_ciclo: ciclo.id,padronesSeleccionados };
      dialog.current.close();
      Api.create('admin/sic/confirmar_bajas', data)
        .then(response => {
          if(response.status === 200){
            setIdsSeleccionados([]);
            getData();
            setConfirmBajas(!confirmBajas);
            setBlocking(false);
            Swal.fire({
              icon: 'success',
              title: '',
              text: `Se ha confirmado la baja de ${idsSeleccionados.length} productores`,
            });
          }
        })
        .catch(e => {
          setBlocking(false);
        });
    }
  }

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/padron">Padrón</Link>
          </li>
          <li className="breadcrumb-item active">Padrón bajas</li>
        </ol>

        <div className="btn-group" role='group' style={{ borderRadius: 1 }}>
          <button type="button"
            onClick={() => getPendientes()}
            className={"btn btn-light"}
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor : ((btnPendientes) ? '#FFEED9' : 'transparent'),
              borderWidth : 1,
              borderColor : '#d4d4d4'
            }}
            disabled={btnPendientes}
          >
            Pendientes
          </button>

          <button type="button"
            onClick={() => getHistorial()}
            className={"btn btn-light"}
            style={{
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor : ((!btnPendientes) ? '#FFEED9' : 'transparent'),
              borderWidth : 1,
              borderColor : '#d4d4d4'
            }}
            disabled={!btnPendientes}
          >
            Historial
          </button>

        </div>
      </Breadcrumb>

      <CardContainer height={'100%'}>

        <div className="row">

          <div className="col-2">

            <SelectCiclo
              setFilter={evt => {
                setFilter(evt);
              }}></SelectCiclo>

          </div>

          <div className="col-7">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('PADRON_BAJAS_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/${report}/${ciclo?.uuid}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <Pagination
          blocking={blocking}
          columns={columns}
          data={data}
          pagination={true}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></Pagination>
          {btnPendientes && (

                  <button
                  disabled={idsSeleccionados.length == 0}
                  onClick={() => dialog.current.open()}
                  className="btn btn-outline-secondary btn-sirio" style={{borderRadius: '20px 20px 20px 20px'}}
                  >Dar de baja
                  </button>

          )}
      </CardContainer>
      <ModalPadronAltas ref={dialog} type="Baja" title="Confirmar baja de productores" onConfirm={(padronesSeleccionados) => {enviarBaja(padronesSeleccionados)}}></ModalPadronAltas>
    </div>
  );
};

export default PadronBajasList;
