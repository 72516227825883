import React, { useContext, createContext, useState } from 'react';
import { Api } from './../../app-controller.js';
import env from './../../environment.js';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */

const authContext = createContext();

/**
 * ProvideAuth wraps all our authentication methods
 */
export function ProvideAuth({ children }) {
  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem(`${env.appName}-user`));
  });

  const login = state => {
    //call to function login in API
    return Api.fetch('auth/login', state, 'POST').then(response => {
      //setting user and saving token and user to mantain session
      let usuario = { ...response.user, permissions: response.permissions };
      setUser(usuario);
      localStorage.setItem(`${env.appName}-token`, response.token);
      localStorage.setItem(`${env.appName}-user`, JSON.stringify(usuario));
      localStorage.setItem(`${env.appName}-sesion`, JSON.stringify(usuario));
      Api.user_profile = usuario;
      return usuario;
    });
  };

  const logout = () => {
    localStorage.clear(`${env.appName}-token`);
    localStorage.clear(`${env.appName}-user`);
    localStorage.clear(`${env.appName}-sesion`);
    window.location.href = '/user/login-v3';
  };

  const profile = () => {
    return Api.fetch('auth/profile', null, 'POST').then(data => {
      return data;
    });
  };

  const checkUser = () => {
    return JSON.parse(localStorage.getItem(`${env.appName}-user`));
  };

  const isAdmin = () => {
    return user.id_rol === 1;
  };

  const getCertification = () => {
    if (user !== null && user.certificacion && user.certificacion !== '') {
      return user.certificacion;
    } else {
      return 'certimex';
    }
  };

  const hasAccess = permission => {
    if (typeof permission !== 'undefined' && user !== null && user.permissions.length) {
      let found = user.permissions.filter(x => x.includes(permission)).length !== 0;
      return found;
    } else {
      return true;
    }
  };

  const hasPermission = permission_action => {
    if (typeof permission_action !== 'undefined' && user != null && user.permissions.length) {
      let found = user.permissions.filter(x => x === permission_action).length !== 0;
      return found;
    } else {
      return true;
    }
  };

  const auth = { user, login, logout, profile, checkUser, isAdmin, getCertification, hasAccess, hasPermission };

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * useAuth hook enables any component to get the current auth state and re-render if it changes.
 **/

export function useAuth() {
  return useContext(authContext);
}
