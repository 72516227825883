import React, { useState, useEffect } from 'react';
import Card from '../../components/card/card.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import CardColor from '../../components/card/card-color.jsx';
import HalfDonut from '../../components/chart/half-donut.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import NVD3Chart from 'react-nvd3';
import d3 from 'd3';
import env from '../../environment.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SelectCentroAcopio from '../../components/select/select-centro-acopio.jsx';
import './acopio-resume.css';

const AcopioResume = () => {
  const auth = useAuth();
  const [blocking, setBlocking] = useState(true);

  const [ciclo, setCiclo] = useState(0);
  const [centroAcopio, setCentroAcopio] = useState(-1);

  const [totales, setTotales] = useState([]);
  const [totalesXCategoria, setTotalesXCategoria] = useState([]);
  const [totalesEfectivo, setTotalesEfectivo] = useState([]);
  const [totalesEfectivo2, setTotalesEfectivo2] = useState([]);
  const [avanceEfectivo, setAvanceEfectivo] = useState(null);
  const [totalesAnticipos, setTotalesAnticipos] = useState([]);
  // const [totalesAnticiposValores, setTotalesAnticiposValores] = useState([]);
  const [avanceAnticipos, setAvanceAnticipos] = useState(null);

  const [totalesKgs, setTotalesKgs] = useState(0);
  const [totalesKgsEnt, setTotalesKgsEnt] = useState(0);
  const [totalesKgsDis, setTotalesKgsDis] = useState(0);
  const [totalesKgsRec, setTotalesKgsRec] = useState(0);

  const [totalesEntregas, setTotalesEntregas] = useState([]);
  const [totalesEntregasValores, setTotalesEntregasValores] = useState([]);
  // const [avanceEntregas, setAvanceEntregas] = useState(null);

  const [chartOptions, setChartOptions] = useState({
    noData: '',
  });
  // const [chartOptionsAnticipos, setChartOptionsAnticipos] = useState({});

  const [chartOptions1] = useState({
    donut: false,
    noData: '',
    growOnHover: false,
    margin: { left: 0, right: 0, top: 0, bottom: 0 },
    donutRatio: 0.5,
    showLegend: true,
    legendPosition: 'bottom',
    showLabels: true,
    wrapLabels: true,
    labelType: 'percent',
    labelFormat: d3.format(',.0f'),
  });

  const [chartOptions2] = useState({
    donut: false,
    noData: '',
    growOnHover: false,
    margin: { left: 0, right: 0, top: 0, bottom: 0 },
    donutRatio: 0.5,
    showLegend: true,
    legendPosition: 'bottom',
    showLabels: true,
    labelType: 'percent',
    labelFormat: d3.format(',.2f'),
  });

  const setFilterCiclo = value => {
    setCiclo(value.target.id);
  };

  const setFilterCentroA = value => {
    if (value.target == null) {
      setCentroAcopio(0);
    } else {
      setCentroAcopio(value.target.id);
    }
  };

  useEffect(() => {
    if (ciclo != 0 && centroAcopio != -1) {
      setTotales([]);
      setTotalesKgs(0);
      setTotalesKgsEnt(0);
      setTotalesKgsDis(0);
      setTotalesKgsRec(0);

      setTotalesXCategoria([]);
      setTotales([]);
      // setAvanceEntregas(0);
      setTotalesEntregas([]);
      setTotalesEntregasValores([]);

      setAvanceAnticipos(null);
      setTotalesAnticipos([]);

      setAvanceEfectivo(null);
      setTotalesEfectivo([]);
      setTotalesEfectivo2([]);

      setBlocking(true);

      Api.Acopio.get(`dashboard/${ciclo}/${auth.user.id_cooperativa}/${centroAcopio}`)
        .then(response => {
          let entregas = response.data.entregas;
          setTotalesKgs(entregas.kg_totales);
          setTotalesKgsEnt(entregas.kg_en_bodega);
          setTotalesKgsDis(entregas.kg_en_centro_acopio);
          setTotalesKgsRec(entregas.kg_en_rechazo);

          let entregas_x_cat = entregas.por_categoria;
          setTotalesXCategoria([
            { label: 'Sin categoría', value: entregas_x_cat.sc, color: '#EFEFEF' },
            { label: 'Transición 0', value: entregas_x_cat.t0, color: '#D87748' },
            { label: 'Transición 1', value: entregas_x_cat.t1, color: '#FFB94C' },
            { label: 'Transición 2', value: entregas_x_cat.t2, color: '#C7AE34' },
            { label: 'Orgánico', value: entregas_x_cat.org, color: '#4A6700' },
          ]);

          let cuentas = response.data.cuentas;
          setTotales([
            { label: 'Con una entrega', value: cuentas.con_entrega, color: '#A46B00' },
            { label: 'Sin entregar', value: cuentas.sin_entrega, color: '#E49D2A' },
          ]);

          let efectivo = response.data.efectivo;
          var total_kgs_ent = efectivo.valor_cafe;
          var total_kgs_est = efectivo.valor_cafe_est;

          //TEST
          // let total_kgs_ent = 0;	let total_kgs_est = 0; // => 0%
          // let total_kgs_ent = 0;	let total_kgs_est = 100; // => 100%
          // let total_kgs_ent = 200;	let total_kgs_est = 0; // => 100% - (200%)
          // let total_kgs_ent = 200;	let total_kgs_est = 50; // => 100% - (400%)
          // let total_kgs_ent = 200;	let total_kgs_est = 200; // => 100%
          // let total_kgs_ent = 200;	let total_kgs_est = 400; // => 50%

          let avance_entregas = 0;
          let cafe_por_acopiar = 0;
          if (total_kgs_ent == 0 && total_kgs_est == 0) {
            // CEROS
          } else if (total_kgs_ent >= total_kgs_est) {
            // avance_entregas = (total_kgs_est != 0) ? parseInt((total_kgs_ent * 100) / total_kgs_est): total_kgs_ent;
            avance_entregas = 100;
          } else {
            avance_entregas = parseInt((total_kgs_ent * 100) / total_kgs_est);
            cafe_por_acopiar = total_kgs_est - total_kgs_ent;
          }

          // setAvanceEntregas(avance_entregas);

          setChartOptions({
            title: avance_entregas + '%',
            donut: true,
            noData: '',
            growOnHover: false,
            margin: { left: 0, right: 0, top: 0, bottom: 0 },
            donutRatio: 0.6,
            showLegend: false,
            showLabels: false,
            // labelType: "percent",
            labelFormat: d3.format('$,.2f'),
          });

          setTotalesEntregas([
            { label: 'Valor de café entregado', value: total_kgs_ent, color: '#FFB94C' },
            { label: 'Valor de café por acopiar', value: cafe_por_acopiar, color: '#FFEED9' },
          ]);

          setTotalesEntregasValores([
            { label: 'Valor de café entregado', value: total_kgs_ent, color: '#FFB94C' },
            { label: 'Estimación valor de café por acopiar', value: total_kgs_est, color: '#EFEFEF' },
          ]);

          let anticipos = response.data.anticipos;
          var anticipos_prod = anticipos.total;
          var anticipos_rec = anticipos.total_recuperados;
          var avance_anticipos = parseInt(anticipos_prod != 0 ? (anticipos_rec * 100) / anticipos_prod : 0);
          setAvanceAnticipos(avance_anticipos);

          // setChartOptionsAnticipos({
          //   title: avance_anticipos + '%',
          //   titleOffset: -20,
          //   donut: true,
          //   growOnHover: false,
          //   margin: { left: 10, right: 10, top: 0, bottom: 0 },
          //   donutRatio: 0.5,
          //   showLegend: true,
          //   showLabels: false,
          //   labelType: 'percent',
          //   labelFormat: d3.format(',.0f'),
          //   startAngle: function(d) {
          //     return d.startAngle / 2 - Math.PI / 2;
          //   },
          //   endAngle: function(d) {
          //     return d.endAngle / 2 - Math.PI / 2;
          //   },
          // });

          setTotalesAnticipos([
            { label: 'Anticipos recuperados', value: anticipos_rec, color: '#E49D2A' },
            { label: 'Efectivo otorgado a anticipos', value: anticipos_prod, color: '#4A6700' },
          ]);

          // setTotalesAnticiposValores([
          //   { label: 'Anticipos recuperados', value: anticipos_rec, color: '#BC8268' },
          //   { label: 'Anticipos pendientes', value: anticipos_prod - anticipos_rec, color: '#dee2e6' },
          // ]);

          var total_efec_otorgado = efectivo.otorgado;
          var total_efec_pagado = efectivo.pagado_prod;

          var avance_efectivo =
            total_efec_otorgado != 0 ? parseInt((total_efec_pagado * 100) / total_efec_otorgado) : 0;
          setAvanceEfectivo(avance_efectivo);

          setTotalesEfectivo([
            { label: 'Pagado a productores', value: total_efec_pagado, color: '#C7AE34' },
            { label: 'Efectivo otorgado para pagos', value: total_efec_otorgado, color: '#4A6700' },
          ]);

          var efec_deuda = 0;
          setTotalesEfectivo2([
            { label: 'Efectivo disponible', value: efectivo.saldo, color: '#9EC640' },
            { label: 'Deuda de pagos', value: efec_deuda, color: '#F65858' },
          ]);

          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
          setBlocking(false);
        });
    }
  }, [ciclo, centroAcopio]);

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item active">Acopio</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilterCiclo(evt);
          }}
          className="float-left mr-2"></SelectCiclo>
        <SelectCentroAcopio
          setFilter={evt => {
            setFilterCentroA(evt);
          }}
          className="float-left"></SelectCentroAcopio>
      </Breadcrumb>

      <div className="row">
        <div className="col-xl-3 pr-3 pl-0">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardContainer height={400}>
              <div className="row card-dashboard m-0 p-0 h-100">
                <div className="col-xl-12 col-md-12 text-center">
                  <h1 className="font-lato-normal-600 w-100">
                    {totalesKgs.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </h1>
                  <h6 className="font-lato-normal-400 w-100">KGs totales entregados</h6>
                </div>
              </div>
            </CardContainer>
          </BlockUi>
        </div>
        <div className="col-xl-3 pr-4">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <Card
              height={120}
              label="KGs entregados a bodega"
              value={totalesKgsEnt.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.entregado}></Card>
          </BlockUi>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <Card
              height={120}
              label="KGs disponibles en centro de acopio actual"
              value={totalesKgsDis.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.disponible}></Card>
          </BlockUi>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <Card
              height={120}
              label="KGs rechazados"
              value={totalesKgsRec.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.rechazado}></Card>
          </BlockUi>
        </div>
        <div className="col-xl-3 pr-4">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <div
              className="row text-center card-graph"
              style={{ height: 400, backgroundColor: 'white', borderRadius: '20px 20px 20px 20px', padding: '20px' }}>
              <h6 className="font-lato-normal-400">Productores que han entregado café</h6>
              <div className="col-xl-12 col-md-12">
                <NVD3Chart
                  type="pieChart"
                  datum={totales}
                  height={300}
                  options={chartOptions1}
                  x="label"
                  y="value"
                  label="percent"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </BlockUi>
        </div>
        <div className="col-xl-3">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <div
              className="row text-center card-graph"
              style={{ height: 400, backgroundColor: 'white', borderRadius: '20px 20px 20px 20px', padding: '20px' }}>
              <h6 className="font-lato-normal-400">Volumen de café entregado por categoría</h6>
              <div className="col-xl-12 col-md-12">
                <NVD3Chart
                  type="pieChart"
                  datum={totalesXCategoria}
                  height={300}
                  options={chartOptions2}
                  x="label"
                  y="value"
                  label="percent"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </BlockUi>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-8 pr-4">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <div
              className="row card-graph"
              style={{ height: 500, backgroundColor: 'white', borderRadius: '20px 20px 20px 20px', padding: '20px' }}>
              <h6 className="font-lato-normal-400 text-center">Efectivo</h6>
              <div className="col-xl-6 col-md-6">
                <NVD3Chart
                  type="pieChart"
                  datum={totalesEntregas}
                  height={300}
                  options={chartOptions}
                  x="label"
                  y="value"
                  label="percent"
                  style={{ width: '100%' }}
                />
                <div className="container">
                  <div className="row justify-content-center">
                    {totalesEntregasValores.map((item, i) => {
                      return (
                        <div key={i} className="col-xl-6 col-md-6">
                          <CardColor
                            key={10 + i}
                            height={120}
                            label={item.label}
                            value={(item.value ? item.value : 0).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            color={item.color}
                            icon={'square'}
                            size={'18px'}
                            style={{ textAlign: 'center' }}></CardColor>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6" style={{ paddingTop: 6 }}>
                {avanceEfectivo != null && (
                  <div
                    className="row card-graph"
                    style={{
                      height: 350,
                      backgroundColor: 'white',
                      borderRadius: '20px 20px 20px 20px',
                      padding: '20px',
                    }}>
                    <div className="container">
                      <div
                        className="row justify-content-center mb-4"
                        style={{ border: '#dee2e6 solid 1px', borderRadius: '8px' }}>
                        {totalesEfectivo2.map((item, i) => {
                          return (
                            <div key={i} className="col-xl-6 col-md-6">
                              <CardColor
                                key={10 + i}
                                height={80}
                                label={item.label}
                                value={(item.value ? item.value : 0).toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                color={item.color}
                                mode={'normal'}
                                size={'18px'}
                                style={{ textAlign: 'center', padding: '0px', margin: '0px' }}></CardColor>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                      <h1 className="font-lato-normal-600 halfdonut-percent">
                        {avanceEfectivo.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        %
                      </h1>
                      <HalfDonut value={avanceEfectivo} color={'#C7AE34'} width={250}></HalfDonut>
                    </div>
                    <div className="container">
                      <div className="row justify-content-center">
                        {totalesEfectivo.map((item, i) => {
                          return (
                            <div key={i} className="col-xl-6 col-md-6">
                              <CardColor
                                key={10 + i}
                                height={120}
                                label={item.label}
                                value={(item.value ? item.value : 0).toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                color={item.color}
                                mode={'onlyData'}
                                size={'18px'}
                                style={{ textAlign: 'center' }}></CardColor>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </BlockUi>
        </div>
        <div className="col-xl-4">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <div
              className="row card-graph"
              style={{ height: 300, backgroundColor: 'white', borderRadius: '20px 20px 20px 20px', padding: '20px' }}>
              <h6 className="font-lato-normal-400 text-center">Anticipos</h6>
              {avanceAnticipos != null && (
                <div className="col-xl-12 col-md-12">
                  <h1 className="font-lato-normal-600 halfdonut-percent">
                    {avanceAnticipos.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%
                  </h1>
                  <HalfDonut value={avanceAnticipos} color={'#BC8268'} width={250}></HalfDonut>
                </div>
              )}
              <div className="container">
                <div className="row justify-content-center">
                  {totalesAnticipos.map((item, i) => {
                    return (
                      <div key={i} className="col-xl-6 col-md-6">
                        <CardColor
                          key={10 + i}
                          height={120}
                          label={item.label}
                          value={(item.value ? item.value : 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          color={item.color}
                          mode={'onlyData'}
                          size={'18px'}
                          style={{ textAlign: 'center' }}></CardColor>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default AcopioResume;
